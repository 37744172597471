// export const API_URL = "http://localhost:5000/api/"
export const API_URL = "https://penguins-e-learning-api.onrender.com/api/"
// export const API_URL = "http://54.174.4.45/api/"
// export const API_URL = "https://api.algomical.com/api/"

export const APP_NAME = "Algomical"
export const GOOGLE_CLIENT_ID = "430737810159-2n1q03v3o4l074hldjs047pnhjgt2vgt.apps.googleusercontent.com"
export const STRIPE_TEST_API_KEY = "pk_test_51Gu1S1HGILcb4r5nUfi5ljrG0bEYWNro1pUlbB4HA0LK4pqrqHuTCNfIdX7M4sYEMyO8hSTgTSUY30t7S3E0Z9fd001MXKFT2t"



export const CONTENT_DRAWER_WIDTH = 300

export const stripeAppearance = {
  theme: 'flat',
  variables: {
    fontFamily: ' "Gill Sans", sans-serif',
    fontLineHeight: '1.5',
    borderRadius: '10px',
    colorBackground: '#F6F8FA',
    colorPrimaryText: '#262626'
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
      padding: '12px'
    },
    '.Input': {
      padding: '12px'
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray'
    },
    '.Tab': {
      padding: '10px 12px 8px 12px',
      border: 'none'
    },
    '.Tab:hover': {
      border: 'none',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      border: 'none',
      backgroundColor: '#fff',
      boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
    },
    '.Label': {
      fontWeight: '500'
    }
  }
}

export const inputStyles = { 
    backgroundColor: "#F4F5FA", 
    borderColor: "#F4F5FA",
    width: '100%',
    padding: '8px',
    border: '1px solid #F4F5FA',
    boxSizing: 'border-box',
    borderRadius: '5px',

}

export const VIDEO_SOURCE_TYPE = ["VIMEO", "VDOCIPHER"]


export const PROGRESS_STATUS = {
  NOT_STARTED: 'NOT STARTED',
  IN_PROGRESS: 'IN PROGRESS',
  COMPLETED: 'COMPLETED',
}



export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
  PAUSED: 'PAUSED',
}