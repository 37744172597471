import axios from "axios";
import { API_URL } from "../../Utils/constants";

export const getAllSubscriptionPlans = (page = 1, pageLimit = 10) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}plans?page=${page}&pageLimit=${pageLimit}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})


export const getUserSubscriptionPlan = (tokenId, userId, page = 1, pageLimit = 10) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .get(`${API_URL}students/${userId}/subscriptions?page=${page}&pageLimit=${pageLimit}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})


export const createUserSubscriptionPlan = (tokenId, userId, itemPriceId, planId, paymentIntentId, paymentMethodId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    
    let data = {
        planId: planId,
        paymentIntentId: paymentIntentId,
        paymentMethodId: paymentMethodId,
        itemPrice: itemPriceId
    }
        
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .post(API_URL + `students/${userId}/subscriptions`, data, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})


export const cancelSubscriptionPlan = (tokenId, userId, subscriptionId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    
    let successUrl = 
            API_URL + `students/${userId}/subscriptions/pages/cancel/${subscriptionId}`
        
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .get(
            successUrl, 
            headers
        )
        .then(successCallBack)
        .catch(errorCallBack)
      
})
