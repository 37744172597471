import axios from "axios";
import { API_URL } from "../../Utils/constants";

export const addProgressDetails =  async (tokenId, data, userId, topicContentId = null) => {


    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    const actionFn = topicContentId?
        axios
            .put(`${API_URL}students/${userId}/course-progress/${topicContentId}`, data, headers)
        :
        axios
            .post(`${API_URL}students/${userId}/course-progress`, data, headers)
    
    return await actionFn      
}

export const getUserProgressDetails = async (tokenId, userId, page = 1, pageLimit = 100) => {

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    return await axios
        .get(`${API_URL}students/${userId}/course-progress?page=${page}&pageLimit=${pageLimit}`, headers)
      
}


export const getUserTopicContentProgressDetails = async (tokenId, userId, topicContentId, page = 1, pageLimit = 100) => {

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    return await axios
        .get(`${API_URL}students/${userId}/course-progress/search-topic-content/${topicContentId}/?page=${page}&pageLimit=${pageLimit}`, headers)
      
}

export const getUserProgressStats = async (tokenId, userId) => {

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    return await axios
        .get(`${API_URL}students/${userId}/course-progress/pages/stats`, headers)
      
}