import axios from "axios"
import { API_URL } from "../../Utils/constants"

export const updateUserDetails = (tokenId, userId, data) => new Promise((successCallBack, errorCallBack)=>{
    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .put(`${API_URL}users/${userId}`, data, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const getUserDetails = async (userId) => {
    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
    }
    return await axios
        .get(`${API_URL}users/${userId}`, headers)
      
}