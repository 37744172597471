import { Button, Card, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router'
import { capitalizeFirstLetter, getSubItemPriceAmount, getSubItemPriceCurrency } from '../../Utils/functions'

export default function SubscriptionPlanCard(props) {

    const { title, description, plan, itemPriceType, userSubscription, isCurrentPlan } = props
    const navigate = useNavigate()
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null


    const handleSubscribe = () => {
        if(user) {
            navigate(`/pages/checkout/${plan.title.toLowerCase()}/${itemPriceType}`)
        }else {
            navigate(`/auth/login/?redirect=/pages/checkout/${plan.title.toLowerCase()}/${itemPriceType}`)
        }
    }

    const handleRedirectToLoginPage = () => navigate(`/auth/login`)

    

    return (
        
        <Card sx={{width: {md: '330px', xs: '100%'}, mb: 2, mr: {md: 2, xs: 0}, borderRadius: '8px', py: 2 }} >
            <Box className="container">

                
                <Box className="text-center">
                    <Typography variant='h4' className='fw-bold ' >
                        {title}
                    </Typography>

                    <Box className="d-flex justify-content-center align-items-center">
                        <Typography variant='h5' color="secondary" className='fw-bold' sx={{my: 2}} >
                            {
                                getSubItemPriceCurrency(plan) + " "
                                
                            }
                            {
                                itemPriceType.toLowerCase() === "monthly"?
                                    getSubItemPriceAmount(plan)
                                :
                                    getSubItemPriceAmount(plan, false)
                                

                            }
                        </Typography>
                        {
                            
                            itemPriceType?
                            
                                <Typography variant='span' sx={{ml: 0.5, float: 'bottom'}}>
                                    {
                                        
                                        itemPriceType.toLowerCase() === "monthly"?
                                            "/ Month"
                                        :
                                            "/ Year"
                                    }
                                    
                                </Typography>
                            :""
                        }

                    </Box>
                    
                    <Box sx={{minHeight: '120px'}}>
                        <Typography variant='span' sx={{ml: 2, }}>
                            {
                                description
                            }
                        </Typography>
                    </Box>

                    <Box sx={{mt: 3}}>
                        {
                            isCurrentPlan?
                                plan.title.toLowerCase() === "free" && !user?
                                    <Button onClick={handleRedirectToLoginPage} color="primary" variant="contained" sx={{color: '#000', textTransform: 'none'}}>
                                        Sign Up for Free
                                    </Button>
                                :
                                    <Box>
                                        {
                                            plan.title.toLowerCase() === "free"?
                                                ""
                                            :
                                                <Typography 
                                                    variant="span" 
                                                    className="rounded-pill"
                                                    sx={{
                                                        backgroundColor: '#F3D849',
                                                        fontSize: '12px',
                                                        py: 0.5,
                                                        px: 1,
                                                        color: '#fff'
                                                    }}
                                                >
                                                    {
                                                        capitalizeFirstLetter((userSubscription.status || "Active").toLowerCase())
                                                    }
                                                </Typography>
                                            
                                        }
                                        <Typography color="secondary" sx={{mt: .8}}>
                                            Current Plan
                                        </Typography>
                                        {
                                            userSubscription.endsAt && plan.title.toLowerCase() !== "free"?
                                                <Box>
                                                    <Typography 
                                                        variant="span"
                                                        sx={{
                                                            fontSize: '12px',
                                                            py: 0.5,
                                                            px: 1,
                                                        }}
                                                    >
                                                        {
                                                            userSubscription.cancelAtPeriodEnd === true?
                                                            "Ends At: "
                                                            :
                                                            "Next billing: "
                                                        }
                                                        {
                                                            new Date(parseFloat(userSubscription.endsAt ))                                                    
                                                            .toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', })
                                                        }
                                                    </Typography>
                                                    {/* <Button color="grey" variant="contained" sx={{mt: 1, color: '#000', textTransform: 'none', boxShadow: 'none'}}>
                                                        Cancel Subcription
                                                    </Button> */}
                                                </Box>

                                            :""
                                        }

                                    </Box>
                            :plan.title.toLowerCase() !== "free"?
                                <Button onClick={handleSubscribe} color="primary" variant="contained" sx={{color: '#000', textTransform: 'none'}}>
                                    {
                                        !user?
                                            "Purchase"
                                        :
                                            "Subscribe"
                                    }
                                </Button>
                            :
                                ""
                        }
                    </Box>

                </Box>

            </Box>
        </Card>
    )

}