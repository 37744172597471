import { configureStore } from '@reduxjs/toolkit'
import courseReducer from './Courses/courseSlice'
import chapterReducer from './Courses/chapterSlice'
import topicReducer from './Courses/topicSlice'
import topicContentReducer from './Courses/topicContentSlice'
import subscriptionReducer from './Subscriptions/subscriptionSlice'

export default configureStore({
  reducer: {
    courseReducer: courseReducer,
    chapterReducer: chapterReducer,
    topicReducer: topicReducer,
    topicContentReducer: topicContentReducer,
    subscriptionReducer: subscriptionReducer,
  }
})