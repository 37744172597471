import axios from "axios"
import { API_URL } from "../../Utils/constants"


export const getStripePaymentIntent = async (tokenId, userId, amount, currency = 'usd') => {

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    
    let successUrl = 
            API_URL + `students/${userId}/payments/pages/create-stripe-payment-intent`
        
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    const data = {
        amount: amount,
        currency: currency
    }
    return await axios.post(successUrl, data, headers)
      
}
