import { createSlice } from '@reduxjs/toolkit'

export const courseSlice = createSlice({
  name: 'courseSlice',
  initialState: {
    data: {
      data: []
    }
  },
  reducers: {
    setAllCourses: (state, action) => {
      state.data = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setAllCourses } = courseSlice.actions

export default courseSlice.reducer