import { createSlice } from '@reduxjs/toolkit'

export const topicSlice = createSlice({
  name: 'topicSlice',
  initialState: {
    data: {
      data: {}
    }
  },
  reducers: {
    setAllTopics: (state, action) => {
      state.data = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setAllTopics } = topicSlice.actions

export default topicSlice.reducer