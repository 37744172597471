
import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from './checkout'
import { RiseLoader } from 'react-spinners'
import { stripeAppearance, STRIPE_TEST_API_KEY } from '../../Utils/constants'
import { Helmet } from 'react-helmet'
import { getStripePaymentIntent } from '../../Controller/Payments'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useParams } from 'react-router'
import { getSubItemPriceAmount, getSubItemPriceCurrency } from '../../Utils/functions'


  

export default function SubscriptionSetupPage(props) {

    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null
    const { planTitle, itemPriceId } = useParams()
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState("")
    const [stripeCustId, setStripeCustId] = useState("")
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    const [selectedSubscription, setSelectedSubscription] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const options = {
        appearance: stripeAppearance
    }

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    useEffect(() => {
        
        

        if(stripePromise === null) {
            loadStripe(
                STRIPE_TEST_API_KEY
                // ,{stripeAccount: stripeCustId}
            )
            .then((data)=>{
                console.log(data)
                setStripePromise(data)
            })
            .catch((err)=>{
                
                console.log("err", err)
            })
        }
        return () => null
    }, [stripePromise, stripeCustId])

    useEffect(() => {
        if(subscriptionReducer.data.data.length > 0) {
            const selSub = subscriptionReducer.data.data.filter(item=>item.title.toLowerCase() === planTitle.toLowerCase())
            if(selSub.length > 0) setSelectedSubscription(selSub[0])
        }
        
    
        return () => null
    }, [subscriptionReducer])

    useEffect(()=>{
        if(Object.keys(selectedSubscription).length > 0) {

            const amount = getSubItemPriceAmount(selectedSubscription, (itemPriceId && itemPriceId.toString().toUpperCase() === "MONTHLY"))
            const currency = getSubItemPriceCurrency(selectedSubscription, (itemPriceId && itemPriceId.toString().toUpperCase() === "MONTHLY"))


            getStripePaymentIntent(user.token, user.user._id, (amount * 100), (currency || 'usd'))
                .then((data)=>{
                    setClientSecret(data.data.data.clientSecret)
                    setStripeCustId(data.data.data.stripeCustomerId)
                })
                .catch((err)=>{
                    console.log("seceret", err)
                    const msg = err.response && err.response.data && err.response.data.error?
                            err.response.data.error
                        :
                        "Could not retrieve stripe payment intent, try again later"
                    showSnackBar(msg , "error")
                })
        }
    }, [selectedSubscription])


   
    
    return (

        <Box >
            {/* title and meta tags for seo purposes */}
            
            <Helmet>
                <title > Subscribe Today | Access Premium Content | Choose Plan Today | Full Time Access With No Limitations </title>
                <meta name="description" content="Subscribe Today | Access Premium Content | Choose Plan Today | Full Time Access With No Limitations." />
            </Helmet>

            {/* content */}  
        
            {
            
                (stripePromise && clientSecret)?
                    <Elements stripe={stripePromise} options={{...options, clientSecret: clientSecret}}>
                        
                        <CheckoutForm clientSecret={clientSecret} />
                    </Elements>
                :
                <div className='text-center mt-3 py-5'>
                    <RiseLoader
                        loading={true}
                        size={10}
                        color="#F3D849"
                    />
                </div>

            }
        
        
        </Box>

    )

}