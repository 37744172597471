import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import RiseLoader from "react-spinners/RiseLoader"
import NavBar from '../../Components/NavBar'
import { getAllCourses } from '../../Controller/Courses'
import { setAllCourses } from '../../Store/Courses/courseSlice'
import { APP_NAME } from '../../Utils/constants'
import bg_home from '../../Utils/Images/bg-home.jpg'

export default function DashBoardPage(props) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const courseReducer = useSelector(state => state.courseReducer)
    const [allCourses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(courseReducer.data.data.length > 0) {
            setCourses([...courseReducer.data.data])
        } else {
            setIsLoading(true)
            getAllCourses()
                .then((data)=>{
                    // setCourses([...data.data.data])                    
                    dispatch(setAllCourses(data.data))
                    setIsLoading(false)
                })
                .catch((err)=>{
                    console.log(err)
                    setIsLoading(false)
                })
        }
    
        return () => null
    }, [courseReducer, dispatch])
    

    return (
        <Box>

            
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title> {APP_NAME} | Home | Get Started Today | Learn Online Today  </title>
                <meta name="description" content="Home page, get to know who we are and get started with our top most courses." />
            </Helmet>

            {/* content */}

            <Box 
                sx={{
                    height: '100vh',
                    backgroundImage: `url(${bg_home})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    marginTop: '-70px'
                }}
            >
                <NavBar navColor={'transparent'} textColor="#fff" />

                <Box                
                    className="d-flex justify-content-center align-items-center"
                    sx={{height: '90%'}}
                >

                    {
                        isLoading?
                            
                            <RiseLoader
                                color={"#F3D849"}
                                loading={isLoading}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        :
                            allCourses.map((course)=>(
                                <Box  key={course._id}>
                                    
                                    <Typography variant='h3' sx={{textAlign: 'center', color: '#fff', textTransform: 'capitalize'}} className='text-center'>
                                        {course.title}
                                    </Typography>
                                    <Box className="d-flex justify-content-center">
                                        <Button 
                                            sx={{textAlign: 'center', mt: 1, textTransform: 'none', color: '#fff', boxShadow: 'none' }} 
                                            size="large" 
                                            color="primary" 
                                            variant='contained'
                                            onClick={
                                                (e)=>navigate('/course/'+course.title.toLowerCase().replaceAll(" ", "-"))
                                            }
                                        >
                                            Start learning for free
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                    }
                </Box>
            </Box>
        </Box>
    )

}