

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function getSubItemPriceId(plan, isMonthly = true) {
    let id = null
    if(isMonthly) {
        id = plan.monthItemPriceId && typeof plan.monthItemPriceId === 'object'?
                (plan.monthItemPriceId._id || "")
            :""
    } else {
        id = plan.annualItemPriceId && typeof plan.annualItemPriceId === 'object'?
                (plan.annualItemPriceId._id || "")
            :""
    }
    console.log("id", id)
    return id
}

export function getSubItemPriceAmount(plan, isMonthly = true) {
    let amount = 0
    if(isMonthly) {
        amount = plan.monthItemPriceId && typeof plan.monthItemPriceId === 'object'?
                (plan.monthItemPriceId.amount || 0)
            :0
    } else {
        amount = plan.annualItemPriceId && typeof plan.annualItemPriceId === 'object'?
                (plan.annualItemPriceId.amount || 0)
            :0
    }
    return amount
}

export function getSubItemPriceCurrency(plan) {
    let currency = 
        plan.monthItemPriceId  && typeof plan.monthItemPriceId === 'object' && plan.monthItemPriceId.currency?
            plan.monthItemPriceId.currency
        :plan.annualItemPriceId && typeof plan.annualItemPriceId === 'object' && plan.annualItemPriceId.currency?
            plan.annualItemPriceId.currency
        :
            "USD"
    return currency
}
