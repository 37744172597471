import { Avatar, Box, Button, Typography } from '@mui/material'
import React from 'react'


export default function ContentAlertPlaceHolder(props) {

    const {title, description, icon, actionHandler, actionTxt } = props

    return (
        <Box className="d-flex justify-content-center align-items-center" sx={{  height:'83vh' }}>
            <Box sx={{width: {md: '40%', xs: '100%'}}}>
                <Box className="d-flex justify-content-center align-items-center">
                    <Avatar variant="square" sx={{width: '80px', height: '80px'}}>
                        {
                            icon
                        }
                    </Avatar>
                </Box>
                <Typography variant="h5" className="fw-bold text-center" sx={{mt: 2.5}} >
                    {
                        title
                    }
                </Typography>
                <Typography variant="body2" className="text-center" sx={{mt: 1}}>
                    {
                        description
                    }
                </Typography>
                {
                    actionHandler?

                        <Box className="d-flex justify-content-center align-items-center" sx={{ my: 2 }}>
                            <Button onClick={actionHandler} color="primary" variant="contained" sx={{ textTransform: 'none', boxShadow: 'none', color: '#fff' }}>
                                {
                                    actionTxt
                                }
                            </Button>
                        </Box>
                    :""
                }
            </Box>
        </Box>
    )


}