import axios from "axios";
import { API_URL } from "../../Utils/constants";

export const getAllCourses = (page = 1, pageLimit = 10) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}courses?page=${page}&pageLimit=${pageLimit}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const getCourseStats = (courseId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}courses/${courseId}/stats`, headers)
            .then(successCallBack)
            .catch(errorCallBack)
      
})

export const getAllCourseChapters = (courseId, page = 1, pageLimit = 100) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}courses/${courseId}/chapters?page=${page}&pageLimit=${pageLimit}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const getAllCourseFullDetails = (courseId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}all-course-details/${courseId}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const getAllChapterTopics = (chapterId, page = 1, pageLimit = 100) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}chapters/${chapterId}/topics/?page=${page}&pageLimit=${pageLimit}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const getSingleChapterTopic = (chapterId, topicId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}chapters/${chapterId}/topics/${topicId}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})


export const getAllTopicContents = (tokenId, topicId, page = 1, pageLimit = 100) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .get(`${API_URL}topics/${topicId}/topic-contents/?page=${page}&pageLimit=${pageLimit}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const getSingleTopicContents = (tokenId, topicId, topicContentId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
      
    axios
        .get(`${API_URL}topics/${topicId}/topic-contents/${topicContentId}`, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

