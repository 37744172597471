import { CardHeader, Grid, Rating, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { Box } from '@mui/system'
import * as React from 'react'
import { SyncLoader } from 'react-spinners'
import { createRating, updateRating } from '../../Controller/Ratings'
import { inputStyles } from '../../Utils/constants'

export default function AlertModalDialog(props) {

    const { open, handleClose, handler  } = props

    function showSnackBar(msg, variant = "success") {
        alert(msg)
    }
    const handleConfirm = () => {
        handleClose()
        handler()
    }


    return (
        <div>
            <Dialog 
                open={open} 
                onClose={handleClose}
            >
                <Box >
            
                    <Box sx={{padding: '6px', m: 2}}>
                        
                        <Box>

                 
                            <Box>
                                <CardHeader title="Please share your review with us" sx={{pb: 2, pt: 0, my: 0, px: 0}} />
                                <div>

                                    <Typography variant="h5">
                                        Do you wish to share your rating and review with us?
                                    </Typography>
                                    
                                                
                                    <Grid container>
                                        
                                        <Grid item md={12} xs={12} >

                                            <div className='d-flex mt-2'>
                                                <div style={{flex: 1}}></div>
                                                <Button 
                                                    color="primary" 
                                                    onClick={handleClose} 
                                                    className={"rounded border-0 me-2 text-capitalize"}
                                                    style={{background: '#000',border: 'none', color: '#fff'}}
                                                >
                                                    Maybe Later
                                                </Button>
                                                <Button 
                                                    color="primary" 
                                                    onClick={handleConfirm} 
                                                    className={"rounded border-0 text-capitalize"}               
                                                    style={{background: '#F3D849', color: '#000',border: 'none'}}
                                                >
                                                    Yes
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    
                                </div>
                            </Box>
                            

                        </Box>
                        
                    </Box>
                    
                </Box>
            </Dialog>
        </div>
    )
}
