import React, { useEffect, useState } from 'react'
import { Person } from '@mui/icons-material'
import { Avatar, Box, Button, Card, Rating, Typography } from '@mui/material'
import { getUserDetails } from '../../Controller/Users'
import RatingModalDialog from '../RatingModal'
import { deleteRating } from '../../Controller/Ratings'
import { SyncLoader } from 'react-spinners'

export default function RatingCard(props) {

    const { rating } = props
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null
    const [userDet, setUserDet] = useState({})
    const [ratingModalOpen, setRatingModalOpen] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)

    const handleRatingModalOpen = () => {
        setRatingModalOpen(true)
    }

    const handleRatingModalClose = () => {
        setRatingModalOpen(false)
    }

    const handleDeleteRating = () => {
        setIsDeleteLoading(true)
        deleteRating(user.token, user.user._id, rating._id)
        .then(()=>{
            setIsDeleteLoading(false)
            setIsDeleted(true)
        })
        .catch((err)=>{
            setIsDeleteLoading(false)
            console.log(err)
        })
    }


    useEffect(() => {
        
        getUserDetails(rating.userId)
            .then((data)=>{
                setUserDet(data.data.data)
            })
            .catch(console.log)
    
        return () => null
    }, [])
    

    return (
        <Box sx={{ p: 2, display: isDeleted?'none':'block' }} >
            <Box 
                className="d-block d-md-flex align-items-center justify-content-center"
                sx={{
                    mb: 1
                }}
            >

                <Box sx={{mr: 1}} className="d-flex align-items-center justify-content-center" >
                    <Avatar
                        sx={{
                            width: '60px',
                            height: '60px',
                        }}
                        src={userDet.profileImg || null}
                    >
                        <Person sx={{fontSize: '40px'}} />
                    </Avatar>
                </Box>

                <Box className="d-flex align-items-center justify-content-center" sx={{mt: {md: 0, xs: 1}}}>
                    <Box>
                        <Typography variant={"h5"} sx={{fontSize: '16px', mb: 0.4}} className="fw-bold">
                            {userDet.fName || "Anonymous" } {" "} {userDet.lName || "User"}
                        </Typography>
                        <Typography sx={{textAlign: {md: 'left', xs: 'center'}}} >
                            <Rating size="small" name="" value={rating.rating || 0} readOnly />
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{flex: 1}}></Box>
                <Box className="d-flex align-items-center justify-content-center" sx={{fontSize: '14px'}}>
                    {
                        new Date(parseFloat(rating.updatedAt ))                                                    
                            .toLocaleString("en-US", { year: 'numeric', month: 'short', day: 'numeric', })
                    }
                </Box>

            </Box>
            <Box>
                <Typography 
                    variant="body2"
                    sx={{
                        mb: 1
                    }}
                >
                    {
                        rating.review || ""
                    }
                </Typography>
            </Box>
            <Box className="d-flex" sx={{mb: 1}} >
                <Box sx={{flex: 1}}></Box>
                {
                    (user && user.user._id === rating.userId)? 
                        <>
                            <Button color="black" sx={{textTransform: 'none'}} onClick={handleRatingModalOpen} >
                                Edit
                            </Button>
                            <Button color="secondary" sx={{textTransform: 'none'}} onClick={handleDeleteRating} >
                                {
                                    isDeleteLoading?
                                        <SyncLoader
                                            loading={isDeleteLoading}
                                            size={10}
                                            color="#000"
                                        />
                                    :"Delete"
                                }
                                
                            </Button>
                        </>
                    :""
                }
            </Box>
            
            <RatingModalDialog 
                open={ratingModalOpen}
                handleClose={handleRatingModalClose}
                rating={rating}
                courseId={rating.courseId || null} 
                topicContentId={rating.topicContentId || null}
                chapterId={rating.chapterId || null}
                topicId={ rating.topicId || null }
            />
        </Box>
    )

}