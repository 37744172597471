import { Box, Chip, Divider, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import '../../Utils/css/chapterAccordion.css'
import { RotateLoader } from 'react-spinners'
import TopicAccordionDetails from '../TopicAccordionDetails'
import { useSelector } from 'react-redux'
import { getAllChapterTopics } from '../../Controller/Courses'
import TagChip from '../TagChip'
import { capitalizeFirstLetter } from '../../Utils/functions'

export default function ChapterAccordionSummary(props) {

    const { 
        chapter, 
        userSubscription, 
        premiumSubscription, 
        activeTopicTab, 
        handleSetActiveTopicTab, 
        searchResultTabData,
        hideDivider
    } = props
    const [topics, setTopics] = useState([])
    const [sortedTopics, setSortedTopics] = useState([])
    const topicReducer = useSelector(state => state.topicReducer)
    const [isTopicLoading, setIsTopicLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(true)
    // const [isTagLoading, setIsTagLoading] = useState(false)

    


    // useEffect(()=>{

    //     if(activeTopicTab.chapter) {
    //         setIsOpen(activeTopicTab.chapter._id === chapter.chapter._id)
    //     } else {
    //         setIsOpen(idx === 0)
    //     }

    // }, [activeTopicTab])
    
    useEffect(() => {
        

        
        if(chapter.chapter._id) {
            if(topicReducer.data.data && topicReducer.data.data[chapter.chapter._id]) {
                setTopics([...topicReducer.data.data[chapter.chapter._id].data])
                setIsTopicLoading(false)
            }else {
                setIsTopicLoading(true)
                getAllChapterTopics(chapter.chapter._id)
                    .then((topics_)=>{
                        console.log("newDtaa", topics_.data)
                        setTopics([...topics_.data.data])
                        setIsTopicLoading(false)
                    })
                    .catch((err)=>{
                        console.log(err)
                        setIsTopicLoading(false)
                    })
            }
        } 
    
        return () => null
    }, [chapter, topicReducer])

    
    useEffect(()=>{

        if(searchResultTabData.topicContents) {
            const contents_ = topics.filter(item=>searchResultTabData.topics.includes(item.topic._id))
            setSortedTopics(contents_)
            // setIsOpen(contents_.length > 0)
        } else {
            setSortedTopics([...topics])
        }

    }, [searchResultTabData, topics])

    // useEffect(()=>{
    //     if(chapter._id && !isTagLoading && topicTags.length <= 0) {
    //         setIsTagLoading(true)
    //         getTopicTagsByChapter(chapter._id)
    //             .then((data)=>{
                    
    //                 setIsTagLoading(false)
    //                 console.log("tag_data", data.data)
    //                 if(data.data.data){
    //                     setTopicTags(data.data.data)
    //                     // data.data.data.forEach((item)=>{
    //                     //     //get tags details
    //                     //     getSingleTag(item.tagId)
    //                     //         .then((tagResult)=>{
    //                     //             if(selectedTags.filter(item=>item._id === tagResult.data.data._id).length <= 0) {
    //                     //                 selectedTags.push(tagResult.data.data)
    //                     //                 setSelectedTags(
    //                     //                     [
    //                     //                         ...selectedTags
    //                     //                     ]
    //                     //                 )
    //                     //             }
    //                     //         })
    //                     //         .catch(console.log)
    //                     // })
    //                 }
    //             })
    //             .catch((err)=>{
    //                 console.log(err)
    //                 setIsTagLoading(false)
    //             })
    //     }
    // }, [chapter._id])


    return (
        <Box>
            <Box
                sx={{
                    mr: 2
                }}
            >
                <Box 
                    sx={{ width: '100%',  pr: .3,  }} 
                    // className="chapterAccordion"
                >
                    <Box 
                        className="d-flex align-items-center"
                        // onClick={()=>setIsOpen(!isOpen)}
                        sx={{ my: .3,  }}
                    >
                        {/* <Box sx={{ mx: 1 }} >
                            {
                                isOpen?
                                    <ArrowDropDown color="darkGrey" />
                                :
                                    <ArrowRight color="darkGrey" />
                            }
                        </Box> */}
                        
                        <Box sx={{ ml: 4.8 }} >
                            <span style={{ color: '#80868b', fontWeight: 'bold', fontSize: '13px', }}>
                                {
                                    capitalizeFirstLetter((chapter.chapter.title || "").toLowerCase())
                                }
                            </span>
                        </Box>
                        <Box style={{ flex: 1 }}></Box>
                        <Box className="d-flex align-items-center" >
                            {
                                chapter.tags?
                                    chapter.tags.map(item=>
                                        <TagChip
                                            key={item.tagId._id} 
                                            label={item.tagId.title}
                                        />
                                    )
                                :""
                            } 
                        </Box>
                    </Box>
                </Box>
                <Box
                    className={`content ${isOpen ? '':'collapse' }`}
                    // sx={{
                    //     height: isOpen?'auto':0,
                    //     overflow: 'hidden',
                    //     transition: 'height 3s ease'
                    // }}
                >
                    <Box>
                        {
                            isTopicLoading?

                                <Box className="d-flex justify-content-center align-items-center" sx={{py: 2}}>
                                    <RotateLoader
                                        color={"#F3D849"}
                                        loading={isTopicLoading}
                                        size={10}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Box>
                            :
                                sortedTopics.length > 0?
                                    sortedTopics.map((topic)=>(
                                        
                                    
                                        <Box key={topic.topic._id} className="content-container">
                                            <TopicAccordionDetails
                                                
                                                topic={topic}
                                                chapter={chapter.chapter}
                                                userSubscription={userSubscription}
                                                premiumSubscription={premiumSubscription}
                                                activeTopicTab={activeTopicTab}
                                                handleSetActiveTopicTab={handleSetActiveTopicTab}
                                                searchResultTabData={searchResultTabData}
                                            />
                                        </Box>

                                    ))
                                :
                                    <Typography variant="span" sx={{ ml: 5, my: 3, fontSize: '13px', color: '#5f6368' }}>No topics found</Typography>
                        }
                    </Box>
                </Box>

                
            </Box>

            {
                hideDivider === true?
                    ""
                :
                    <Divider 
                        sx={{
                            my: 1.5,
                            background: '#dadce0'
                        }}
                    />
                    
            }
        </Box>
    )

}