import { Button, Card, CardContent, FormControl, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SyncLoader } from 'react-spinners'
import TabPanel from '../../Components/TabPanel'
import { updateUserDetails } from '../../Controller/Users'
import { APP_NAME, inputStyles } from '../../Utils/constants'
import { capitalizeFirstLetter, getSubItemPriceAmount, getSubItemPriceCurrency, getSubItemPriceId } from '../../Utils/functions'
import { cancelSubscriptionPlan } from '../../Controller/Subscriptions'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

export default function SettingsPage(props) {

    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null

    const [isLoading, setIsLoading] = useState(false)
    const [isCancelLoading, setIsCancelLoading] = useState(false)
    const [userDetails, setUserDetails] = useState(user?user.user:{})
    const [tabValue, setTabValue] = React.useState(0)
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    const [userSubscription, setUserSubscription] = useState({})
    const [userSubscriptionPlan, setUserSubscriptionPlan] = useState({})

    const fields = [
        {
            holder: 'First name',
            id: 'fName'
        },
        {
            holder: 'Surname',
            id: 'lName'
        },
        {
            holder: 'City',
            id: 'city'
        },
        {
            holder: 'Country',
            id: 'country'
        },
    ]

    
    if(!user) {
        navigate('/auth/login/?redirect=/pages/settings')
    }

    

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const handleInputs = async (id, value) => {
       setUserDetails({
            ...userDetails,
            [id]: value
       })
    }
    const handleSubmit = () => {    
       if(!userDetails.fName || !userDetails.lName ) {
            alert("First name and surname fields are required.")
            return
       }

       setIsLoading(true)
       updateUserDetails(user.token, user.user._id, userDetails)
            .then((data)=>{
                console.log(data)
                setIsLoading(false)
                user.user = {
                    ...user.user,
                    ...userDetails
                }
                localStorage.setItem("sessionUser", JSON.stringify(user))
                alert('User updated successfully')
            })
            .catch((err)=>{
                setIsLoading(false)
                console.log(err)
                alert('Could not update user. Please try again later.')
            })


    }
  
    const handleCancelSubscriptionPlan = () => {


        setIsCancelLoading(true)
        cancelSubscriptionPlan(user.token, user.user._id, userSubscription._id)
            .then((result)=>{
                setIsCancelLoading(false)
                setUserSubscription({
                    ...userSubscription,
                    cancelAtPeriodEnd: true
                })
                const expiryDate = new Date(parseFloat(userSubscription.endsAt )).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', })
                showSnackBar("You have cancelled your subscription, you plan will end on " + expiryDate, "success")

            })
            .catch((err)=>{
                setIsCancelLoading(false)
                const msg = err.response && err.response.data && err.response.data.error?
                        err.response.data.error
                    :"Could not upgrade your plan, please try again later"
                showSnackBar(msg, "error")                
            })

    }
    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}

    
    useEffect(() => {
        if(
            subscriptionReducer && 
            subscriptionReducer.userSubscription && 
            subscriptionReducer.userSubscription.data && 
            subscriptionReducer.userSubscription.data.length > 0
        ) {
            setUserSubscription(subscriptionReducer.userSubscription.data[0])
            const userPlan = subscriptionReducer.data.data.filter(item=>item._id === subscriptionReducer.userSubscription.data[0].planId)
            setUserSubscriptionPlan(userPlan.length?userPlan[0]:{})
        }
    
      return () => null
    }, [subscriptionReducer])


    return (
        <Box sx={{ mt: 2, display: {md: 'flex', xs: 'flex'}}} className="justify-content-center align-items-center">
                    
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title> {user.user.fName || APP_NAME} {user.user.lName || ""} | Profile Settings </title>
                <meta name="description" content="View and manage your profile data via settings page." />
            </Helmet>

            {/* content */}          
            <Card className="pt-2" sx={{minHeight: '250px', width: {md: '50%', xs: '95%'}, margin: {md: '3px 0px', xs: '3px 2px 3px 2px'}, padding: '6px', borderRadius: '5px'}}>
                {/* <CardHeader
                    title={
                                    
                        <Typography variant="h6" sx={{color: '#000'}}>
                            Edit profile
                        </Typography>
                    }
                    sx={{
                        mb: 0
                    }}
                /> */}
                <Box sx={{  px: 1, pt: 0 }}>
                    <Tabs
                        value={tabValue} 
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                        indicatorColor="primary"
                        textColor="black"
                    >
                        <Tab label={"Edit profile"} {...a11yProps(0)} sx={{textTransform: 'none'}} />
                        <Tab label={"Subscription"} {...a11yProps(1)} sx={{textTransform: 'none'}} />
                    </Tabs>

                </Box>
                    
                <TabPanel value={tabValue} index={0}>

                    <CardContent sx={{mt: 1, pt: 0}}>
                    
                        <Box>
                            
                            {
                                fields.map((item)=>(

                                
                                    <div key={item.id}>
                                                                        
                                        <FormControl variant="standard" fullWidth className="py-1 mb-1" >
                                            <div className='d-flex mb-2'>
                                                <label htmlFor="input-with-icon-adornment4" className='py-0 my-0' style={{fontSize: '14px'}}>
                                                    {
                                                        item.holder
                                                    }
                                                </label>
                                            </div>
                                            <input 
                                                style={{
                                                    ...inputStyles,
                                                    fontSize: '14px'
                                                }}
                                                fullWidth 
                                                id={item.id}
                                                value={
                                                    userDetails[item.id] || ""
                                                }
                                                onChange={e=>handleInputs(item.id, e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                ))
                            }

                            <Button
                                onClick={handleSubmit}
                                className={"rounded border-0 mx-2 text-capitalize p-2 mt-3"}
                                color='primary'
                                variant="contained"
                                sx={{background: '#000',border: 'none', color: "#fff", boxShadow: 'none'}}
                                disabled={isLoading}
                            >
                                {
                                    isLoading?

                                        <SyncLoader
                                            loading={isLoading}
                                            color="#fff"
                                            size={5}
                                        />
                                    :"Save"
                                }
                                
                            </Button>
                        </Box>
                    </CardContent>
                </TabPanel>
                
                <TabPanel value={tabValue} index={1}>

                    <CardContent sx={{mt: 1, pt: 0}}>
                        
                        <Typography variant="h6" sx={{mb: 1 }} >
                            Your subscription plan
                        </Typography>
                        <Box
                            className="d-flex"
                        >
                            <Box>
                                <Box 
                                    sx={{
                                        // justifyContent: {md: 'start', xs:'center'},
                                        fontSize: '14px',
                                        mb: 0.5
                                    }}
                                    className="d-flex"
                                >
                                    
                                    <Typography 
                                        variant="span" 
                                        className="rounded-pill"
                                        color="secondary"
                                        sx={{
                                            fontSize: '15px'
                                        }}
                                    >
                                        {"Current plan:  "}
                                        {
                                            capitalizeFirstLetter( (userSubscriptionPlan.title || "Free").toLowerCase() )
                                        }
                                        {
                                            subscriptionReducer && 
                                            subscriptionReducer.data && 
                                            subscriptionReducer.data.data && 
                                            subscriptionReducer.data.data.length > 0 && 
                                            userSubscription.itemPriceId === getSubItemPriceId(subscriptionReducer.data.data[1], false)?
                                                " ( yearly )"
                                            :
                                                ((userSubscriptionPlan.title || "Free").toLowerCase() === "free")?
                                                    ""
                                                :
                                                    " ( monthly )"
                                        }
                                    </Typography>
                                </Box>

                                {
                                    (userSubscriptionPlan && (userSubscriptionPlan.title || "free").toLowerCase() === "free")?
                                        ""
                                    :
                                        <Box 
                                            sx={{
                                                justifyContent: {md: 'start', xs:'center'},
                                                fontSize: '14px',
                                            }}
                                            className="d-flex"
                                        >

                                            
                                            <span>
                                                Status: 
                                            </span>
                                            
                                            <Typography
                                                variant="span" 
                                                className="rounded-pill"
                                                sx={{
                                                    backgroundColor: '#F3D849',
                                                    fontSize: '11px',
                                                    py: 0.3,
                                                    px: 1,
                                                    color: '#fff',
                                                    ml: 1,
                                                }}
                                            >
                                                {
                                                    capitalizeFirstLetter((userSubscription.status || "Active").toLowerCase())
                                                }
                                            </Typography>

                                            <Typography variant="span" sx={{ mx: 1.5, color: 'rgba(0,0,0,.6)' }} > | </Typography>

                                            
                                            {
                                                userSubscription.endsAt && userSubscriptionPlan.title.toLowerCase() !== "free"?
                                                    
                                                    <Typography 
                                                        variant="span"
                                                    >
                                                        {
                                                            userSubscription.cancelAtPeriodEnd === true?
                                                            "Ends At: "
                                                            :
                                                            "Next billing: "
                                                        }
                                                        {
                                                            new Date(parseFloat(userSubscription.endsAt ))                                                    
                                                            .toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', })
                                                        }
                                                    </Typography>

                                                :""
                                            }

                                            {
                                                
                                                (
                                                    subscriptionReducer &&
                                                    subscriptionReducer.data &&
                                                    subscriptionReducer.data.data &&
                                                    subscriptionReducer.data.data.length > 0
                                                )?
                                                    <>
                                                        <Typography variant="span" sx={{ mx: 1.5, color: 'rgba(0,0,0,.6)' }} > | </Typography>

                                                        <Typography 
                                                            variant="span"
                                                        >
                                                            {
                                                                (getSubItemPriceCurrency(userSubscriptionPlan) || "USD") + " " + parseFloat(getSubItemPriceAmount(userSubscriptionPlan, (userSubscription.itemPriceId === getSubItemPriceId(subscriptionReducer.data.data[1], true)))).toFixed(2)
                                                            }

                                                        </Typography>
                                                    </>

                                                :""
                                            }

                                            
                                        </Box>
                                }
                            </Box>
                            <Box sx={{flex: 1}}></Box>
                        </Box>
                        
                        <Box sx={{mt: 3}}>
                            {
                                (
                                    (userSubscriptionPlan && (userSubscriptionPlan.title || "free").toLowerCase() === "free") ||
                                    (
                                        subscriptionReducer &&
                                        subscriptionReducer.data &&
                                        subscriptionReducer.data.data &&
                                        subscriptionReducer.data.data.length > 0 &&
                                        userSubscription.itemPriceId === getSubItemPriceId(subscriptionReducer.data.data[1], true)
                                    )
                                )?
                                    <Button 
                                        variant="contained" 
                                        color="black" 
                                        size="small"
                                        onClick={()=>navigate("/pages/subscriptions")}
                                        sx={{
                                            textTransform: 'none',
                                            color: '#fff',
                                            mr: 1,
                                            mb: 1
                                        }}
                                    >
                                        Upgrade
                                    </Button>
                                :""
                            }

                            {
                                (
                                    (userSubscriptionPlan && (userSubscriptionPlan.title || "free").toLowerCase() === "free")
                                    ||
                                    (userSubscription.cancelAtPeriodEnd === true)
                                )?
                                    ""

                                :
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        size="small"
                                        sx={{
                                            textTransform: 'none',
                                            mr: 1,
                                            mb: 1,
                                            boxShadow: '1px 1px 2px #d3d3d3'
                                        }}
                                        onClick={handleCancelSubscriptionPlan}
                                    >
                                        {
                                            isCancelLoading?

                                                <SyncLoader
                                                    loading={isCancelLoading}
                                                    color="#000"
                                                    size={5}
                                                />
                                            :"Cancel plan"
                                        }
                                    </Button>
                            }
                        </Box>

                    </CardContent>
                </TabPanel>
                
                
            </Card>
        </Box>
    )
}