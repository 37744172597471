import { CardHeader, Grid, Rating } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { Box } from '@mui/system'
import * as React from 'react'
import { SyncLoader } from 'react-spinners'
import { createRating, updateRating } from '../../Controller/Ratings'
import { inputStyles } from '../../Utils/constants'

export default function RatingModalDialog(props) {

    const { open, handleClose, courseId, topicContentId, chapterId, topicId, rating  } = props
    const [isLoading, setIsLoading] = React.useState(false)

    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null

    const [ratingCount, setRatingCount] = React.useState(0)
    const [review, setReview] = React.useState("")

    const handleReviewChange = (e) => {
        if(e.target.value.length > 500) {

            showSnackBar("Reviews can only have a maximum of 500 characters", "error")

        }else {
            setReview(e.target.value)
        }
    }
    
    const handleConfirm = ()=> {

        if(!review) {
            showSnackBar("Invalid review", "error")
        }else {
            let data = {}
            if(rating !== null) {
                data = {
                    rating:( ratingCount || rating.rating || "" ),
                    review: ( review || rating.review || "" ),
                    updatedAt: parseFloat(new Date().valueOf())
                }
                
                if(user) {

                    setIsLoading(true)
                    updateRating(user.token, user.user._id, topicContentId, data)
                        .then((rating)=>{
                            setIsLoading(false)
                            showSnackBar("Rating update successfully", "success")
                        })
                        .catch((err)=>{
                            console.log(err)
                            setIsLoading(false)
                            showSnackBar(err.response.data.error || "Cannot update rating, please try again later", "error")
                        })

                } else {
                    showSnackBar("Unauthorised access", "error")
                }

            }else {
                data = {
                    rating: ratingCount,
                    review: review,
                    courseId: courseId,
                    chapterId: chapterId,
                    topicId: topicId,
                    topicContentId: topicContentId,
                }
                
                
                if(user) {
                    setIsLoading(true)
                    createRating(user.token, user.user._id, data)
                        .then((rating)=>{
                            setIsLoading(false)
                            showSnackBar("Rating added successfully", "success")
                        })
                        .catch((err)=>{
                            console.log(err)
                            setIsLoading(false)
                            showSnackBar(err.response.data.error || "Cannot add rating, please try again later", "error")
                        })               
                } else {
                    showSnackBar("Unauthorised access", "error")
                }
            }
        }
    }

    function showSnackBar(msg, variant = "success") {
        alert(msg)
    }

    React.useEffect(() => {
      
        if(rating) {
            setReview(rating.review || "")
            setRatingCount(rating.rating || "")
        }

        return () => null
    }, [rating])
    

    

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
            <Dialog 
                open={open} 
                onClose={handleClose}
            >
                <Box >
            
                    <Box sx={{padding: '6px', m: 2}}>
                        
                        <Box>

                 
                            <Box>
                                <CardHeader title="Please share your review with us" sx={{pb: 2, pt: 0, my: 0, px: 0}} />
                                <div>

                                    

                                    <div className="pt-1">
                                        <label htmlFor='eventTitle'>
                                            Your ratings
                                        </label><br />
                                        <Rating
                                            name="serviceRating"
                                            value={ratingCount}
                                            onChange={(event, newValue) => {
                                                setRatingCount(newValue);
                                            }}
                                            max={5}
                                            className="rating-label"
                                            size="large"
                                        />
                                    </div>
                                    <div className='d-flex py-1'>
                                        
                                        <div className='w-100'>
                                        
                                            <label htmlFor='eventTitle'>
                                                Your comments
                                            </label><br />
                                            <textarea id="eventTitle" 
                                                style={inputStyles} 
                                                type="text" 
                                                value={review || ""}
                                                onChange={handleReviewChange}
                                                rows="5"
                                                cols="50"
                                            ></textarea>

                                            <div className='text-right mb-0 pb-0' style={{fontSize: '14px',color: "#999" , fontWeight: 300}}>
                                                {
                                                    review?
                                                        review.length
                                                    :0
                                                } /500
                                            </div>
                                        </div>
                                        

                                    </div>
                                    
                                                
                                    <Grid container>
                                        
                                            <Grid item md={12} xs={12} >

                                                <div className='d-flex mt-2'>
                                                    <div style={{flex: 1}}></div>
                                                    <Button 
                                                        color="primary" 
                                                        onClick={handleClose} 
                                                        className={"rounded border-0 me-2 text-capitalize"}
                                                        disabled={isLoading}                    
                                                        style={{background: '#000',border: 'none', color: '#fff'}}
                                                    >
                                                        Close
                                                    </Button>
                                                    <Button 
                                                        color="primary" 
                                                        onClick={handleConfirm} 
                                                        className={"rounded border-0 text-capitalize"}
                                                        disabled={isLoading}                    
                                                        style={{background: '#F3D849', color: '#000',border: 'none'}}
                                                    >
                                                        {
                                                            isLoading?
                                                            <SyncLoader
                                                                loading={isLoading}
                                                                size={10}
                                                                color="#fff"
                                                            />
                                                            :"Submit"
                                                        }
                                                    </Button>
                                                </div>
                                            </Grid>
                                    </Grid>
                                    
                                </div>
                            </Box>
                            

                        </Box>
                        
                    </Box>
                    
                </Box>
            </Dialog>
        </div>
    )
}
