import { createSlice } from '@reduxjs/toolkit'

export const subscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  initialState: {
    data: {
      data: []
    },
    userSubscription : []
  },
  reducers: {
    setAllSubscriptions: (state, action) => {
      state.data = action.payload
    },
    setUserSubscription: (state, action) => {
      state.userSubscription = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setAllSubscriptions, setUserSubscription } = subscriptionSlice.actions

export default subscriptionSlice.reducer

