
import { Close, FilterList, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Divider, Drawer, IconButton, Link, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { RiseLoader } from 'react-spinners'
import ChapterAccordionSummary from '../../Components/ChapterAccordionSummary'
import { getAllCourseChapters, getAllCourseFullDetails } from '../../Controller/Courses'
import { setAllChapters } from '../../Store/Courses/chapterSlice'
import { setAllTopics } from '../../Store/Courses/topicSlice'
import { CONTENT_DRAWER_WIDTH, inputStyles } from '../../Utils/constants'
import TopicContentPage from '../TopicContent'
import { setAllTopicContent } from '../../Store/Courses/topicContentSlice'




export default function CourseContentPage(props) {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { courseTitle, chapterTitle, topicTitle, topicContentEncodedId } = useParams()
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null
    const chapterReducer = useSelector(state => state.chapterReducer)
    const courseReducer = useSelector(state => state.courseReducer)
    const topicReducer = useSelector(state => state.topicReducer)
    const topicContentReducer = useSelector(state => state.topicContentReducer)
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    const [course, setCourse] = useState({})
    const [userSubscription, setUserSubscription] = useState(null)
    const [premiumSubscription, setPremiumSubscription] = useState({})
    const [chapters, setChapters] = React.useState([])
    const [sortedChapters, setSortedChapters] = React.useState([])
    const [isChapterLoading, setIsChapterLoading] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(true)
    // const [courseStats, setCourseStats] = useState({})
    const [activeTopicTab, setActiveTopicTab] = useState({})
    // const [progressStats, setProgressStats] = useState({})
    const [topicContents, setTopicContents] = useState([])
    const [tabValue, setTabValue] = React.useState(0)
    const [filterChapter, setFilterChapter] = React.useState("")
    const [searchResultTabData, setSearchResultTabData] = React.useState({})


    const handleSetActiveTopicTab = (activeTopicData) => setActiveTopicTab({...activeTopicData, course: course})
    const handleSetSearchResultTabData = (searchResultTabData_) => setSearchResultTabData({...searchResultTabData_})
    
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    function handleOpenCourseLink(e) {
        e.preventDefault()
        navigate(`/course/${courseTitle}`)
    }

    //get course data
    useEffect(() => {
        
        if(courseReducer.data.data.length > 0) {
            const course_  = courseReducer.data.data.filter(item=>item.title.toLowerCase().replaceAll(" ", "-") === courseTitle.toLowerCase())
            if(course_.length > 0) setCourse(course_[0])
        }
    
        return () => null
    }, [courseReducer, courseTitle])

    //get course statistics
    // useEffect(() => {
      
    //     if(course._id) {
    //         getCourseStats(course._id)
    //             .then((data)=>{
    //                 console.log("course", data)
    //                 setCourseStats(data.data.data || {})
    //             })
    //             .catch((err)=>{
    //                 console.log(err)
    //             })
    //     }
    
    //     return () => null
    // }, [course])
    

    //get chapters and topics
    useEffect(() => {
        
        if(chapterReducer.data.data.length > 0) {
            const chapters_ = [...chapterReducer.data.data].sort((a,b)=>(a.chapter.createdAt - b.chapter.createdAt))
            setChapters([...chapters_])
        } else {
            if(course._id) {
                setIsChapterLoading(true)

                getAllCourseFullDetails( course._id )
                    .then((results)=>{
                        console.log("full-details", results)

                        if(results.data.data.chapters) {                  
                            dispatch(setAllChapters(results.data.data.chapters))
                            if(results.data.data.topics) {
                                dispatch(setAllTopics(results.data.data.topics))
                            }
                            if(results.data.data.topicContents) {
                                dispatch(setAllTopicContent(results.data.data.topicContents.data))
                            }
                            setIsChapterLoading(false)

                        } else {


                            getAllCourseChapters(course._id)
                                .then((data)=>{
                                    // setCourses([...data.data.data])                    
                                    dispatch(setAllChapters(data.data))
                                    setIsChapterLoading(false)
                                })
                                .catch((err)=>{
                                    console.log(err)
                                    setIsChapterLoading(false)
                                })
                        }

                    })

            }
        }
    
        return () => null
    }, [chapterReducer, dispatch, course])



    useEffect(()=>{
        if(topicReducer.data && topicReducer.data.data && chapters.length > 0) {
            const chapters_ = chapterTitle?
                    chapters.filter(item=>item.chapter.title.toLowerCase().replaceAll(" ", "-") === chapterTitle.toLowerCase())
                :[]
            const chapter_ = chapters_.length > 0?chapters_[0].chapter:chapters[0].chapter
            if(chapter_._id) {
                let topics = topicReducer.data.data[chapter_._id].data
                const topics_ = topicTitle?
                        topics.filter(item=>item.topic.title.toLowerCase().replaceAll(" ", "-") === topicTitle.toLowerCase())
                    :[]
                const topic = topics_.length > 0?topics_[0]:topics[0]

                //update active tab if active tab topic is not same as the selected one or active topic tab is empty
                if(topic.topic._id && chapter_._id && (!activeTopicTab.topic || (topic.topic._id !== activeTopicTab.topic._id))) {
                    
                    handleSetActiveTopicTab({
                        course: course,
                        chapter: chapter_,
                        topic: topic.topic,
                        topicTags: topic.tags || [],
                        topicContentId: topicContentEncodedId?atob(topicContentEncodedId):null
                    })
                }else {
                    if(!chapterTitle || !topicTitle) {
                        handleSetActiveTopicTab({})
                    }
                }
            } else {
                
                if(!chapterTitle || !topicTitle) {
                    handleSetActiveTopicTab({})
                }
            }
        }
    }, [topicReducer, chapters, chapterTitle, topicTitle, topicContentEncodedId])
    

    //filtering chapters, topics and topic contents on the left menu
    useEffect(()=>{
        if(filterChapter) {

            //search related chapters
            let chapterResults = chapters.filter((item)=>
                (item.chapter.title.toLowerCase().indexOf(filterChapter.toLowerCase()) !== -1)
            ).map(item=>item.chapter._id)
            
            // search related topics
            const topics_ = topicReducer.data.data
            // const chapterIds = []
            let searchTopicResults = []
            Object.keys(topics_).forEach(chapterId => {
                const results_ = topics_[chapterId].data.filter((item)=>
                    (item.topic.title.toLowerCase().indexOf(filterChapter.toLowerCase()) !== -1)
                ).map(item=>item.topic._id)

                searchTopicResults = [...searchTopicResults, ...results_]

                if(results_.length > 0) {
                    chapterResults.push(chapterId)
                }
            })


            // search related topic contents
            console.log(topicContentReducer)
            const topicContents = topicContentReducer.data
                .filter(item=>item.title.toLowerCase().indexOf(filterChapter.toLowerCase()) !== -1)
                
            // const chapterIds = []
            Object.keys(topics_).forEach(chapterId => {
                const results_ = topics_[chapterId].data.filter((item)=>
                    (topicContents.map(item=>item.topicId).includes(item.topic._id))
                ).map(item=>item.topic._id)

                searchTopicResults = [...searchTopicResults, ...results_]

                if(results_.length > 0) {
                    chapterResults.push(chapterId)
                }
            })

            chapterResults = [...new Set(chapterResults)]
            const sortedRes = []
            chapters.forEach(item=>{
                if(chapterResults.includes(item.chapter._id)) {
                    sortedRes.push(item)
                }
            })

            setSortedChapters([...sortedRes])
            handleSetSearchResultTabData({
                topics: [...new Set(searchTopicResults)],
                topicContents: topicContents.map(item=>item._id)
            })

        } else {
            setSortedChapters([...chapters])
            handleSetSearchResultTabData({})
        }
    }, [chapters, filterChapter])

    useEffect(() => {
        if(subscriptionReducer.userSubscription && subscriptionReducer.userSubscription.data && subscriptionReducer.userSubscription.data.length > 0) {
            setUserSubscription(subscriptionReducer.userSubscription.data[0])
        }

        if(subscriptionReducer.data.data.length > 0) {
            const premSubscription = subscriptionReducer.data.data.filter(item=>item.title.toLowerCase() === "premium")
            if(premSubscription.length > 0) setPremiumSubscription(premSubscription[0])
        }
    
      return () => null
    }, [subscriptionReducer])
    

    // useEffect(() => {
      
    //     if(user) {
    //         getUserProgressStats(user.token, user.user._id)
    //             .then((data)=>{

    //                 setProgressStats(data.data.data || {})
    //             })
    //             .catch((err)=>{
    //                 console.log(err)
    //             })
    //     }
    
    //   return () => null
    // }, [user])
    
    

    return (
        <Box  sx={{ display: 'flex' }} >

            
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title> Courses, Training and Tutorials | Learn Online  </title>
                <meta name="description" content="List of courses, training materials and tutorials for you to learn online." />
            </Helmet>

            {/* content */}


            {/* topic navbar and tabs                */}
            <Box 
                sx={{ 
                    py: topicContents.length > 0?0:1.5, 
                    mb: 1, 
                    background: '#fff',
                    boxShadow: '2px 2px 4px #d3d3d3',
                    position: 'fixed',
                    top: '65px',
                    // width: `calc(100% - ${CONTENT_DRAWER_WIDTH}px)`,
                    width: '100%',
                    right: 0,
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                className="sticky-top d-flex"
            >
                <Box 
                    className="d-flex align-items-center"
                    sx={{
                        maxWidth: CONTENT_DRAWER_WIDTH,
                        ml: 1,
                        mr: 4
                    }}
                >

                    <Link 
                        href={`/course/${courseTitle}`}
                        onClick={handleOpenCourseLink}
                        sx={{
                            textDecoration: 'none',
                            color: '#4d4d4d'
                        }}
                    >

                        <Typography 
                            variant="h4" 
                            sx={{
                                fontSize: '18px'
                            }}
                        >
                            {
                                course.title || courseTitle
                            }
                        </Typography>

                    </Link>
                    
                </Box>
                

                {
                    // topicContents.length > 0?

                    //     <Box>

                    //         <Tabs 
                    //             value={tabValue} 
                    //             onChange={handleTabChange} 
                    //             aria-label="basic tabs example"
                    //             indicatorColor="primary"
                    //             textColor="black"
                    //         >
                    //             {
                    //                 topicContents.map((topicContent, idx)=>(
                    //                     <Tab 
                    //                         label={topicContent.title} 
                    //                         key={topicContent._id} 
                    //                         {...a11yProps(idx)} 
                    //                         sx={{
                    //                             textTransform: 'none', 
                    //                             fontSize: '14px',
                    //                             px: 0,
                    //                             mr: 3
                    //                         }} 
                    //                     />
                    //                 ))
                    //             }
                    //         </Tabs>
                    //     </Box>

                    // :""
                }
            </Box>

            {/* topic content drawer/navigation bar */}

            <Box
            >
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={drawerOpen}
                    sx={{
                        width: CONTENT_DRAWER_WIDTH,
                        flexShrink: 0,
                        border: 'none',
                        [`& .MuiDrawer-paper`]: { 
                            width: CONTENT_DRAWER_WIDTH, 
                            boxSizing: 'border-box',
                        },
                        position: 'relative'
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto', pb: '60px', mt: '53px' }}>
                        
                        <Box 
                            sx={{
                                my: 3,
                                mx: 2
                            }}
                        >
                            {/* <Box sx={{ mb: 3 }}>
                                
                                <Box className="d-flex align-items-center" sx={{ mb: .5 }} >
                                    <Typography sx={{color: '#6a7580', fontSize: '14px'}}>
                                        Course progress
                                    </Typography>
                                    <Box sx={{flex: 1}}></Box>
                                    <Typography className="fw-bold" sx={{ fontSize: '16px'}}>
                                        {
                                            progressStats.progress || "0%"
                                        }
                                    </Typography>
                                </Box>
                                <Box>
                                    <LinearProgress 
                                        variant="determinate" 
                                        value={parseFloat(progressStats.progress || 0)}
                                        sx={{p: '3px', borderRadius: '3px'}} 
                                    />
                                </Box>
                            </Box> */}
                            <Box sx={{...inputStyles,  padding: '2px',}} className="d-flex align-items-center" >
                                <FilterList sx={{ mx: 1, fontSize: '16px' }} />
                                <input 
                                    type="text" 
                                    style={{
                                        ...inputStyles, 
                                        padding: '0', 
                                        margin: 0
                                    }} 
                                    placeholder='Filter'
                                    onChange={(e)=>setFilterChapter(e.target.value)}
                                    value={filterChapter}
                                />
                                {
                                    filterChapter?

                                        <IconButton
                                            sx={{
                                                mr: .5,
                                                p: .2
                                            }}
                                            onClick={()=>setFilterChapter("")}
                                        >
                                            
                                            <Close 
                                                sx={{
                                                    fontSize: '16px'
                                                }}
                                            />
                                        </IconButton>
                                        
                                    :""
                                }
                            </Box>
                        </Box>
                        <Divider 
                            sx={{
                                background: '#dadce0'
                            }}
                        />

                        <Box 
                            sx={{ 
                                mt: 2,
                            }}
                        >
                            {
                                isChapterLoading?
                                    
                                    <Box className="d-flex justify-content-center align-items-center" sx={{height: '200px'}}>
                                        <RiseLoader
                                            color={"#F3D849"}
                                            loading={isChapterLoading}
                                            size={15}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </Box>
                                :
                                    <Box >

                                        {
                                            sortedChapters.map((chapter, idx)=>(
                                            
                                                <ChapterAccordionSummary 
                                                    key={chapter.chapter._id} 
                                                    chapter={chapter}
                                                    userSubscription={userSubscription}
                                                    premiumSubscription={premiumSubscription}
                                                    idx={idx}
                                                    handleSetActiveTopicTab={handleSetActiveTopicTab}
                                                    activeTopicTab={activeTopicTab}
                                                    searchResultTabData={searchResultTabData}
                                                    hideDivider={((idx + 1) === sortedChapters.length)}
                                                />
                                            ))
                                        }
                                    </Box>
                            }
                        </Box>

                    </Box>

                    {/* open drawer */}

                    {/* <Box
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            right: '20px',
                            left: 0,
                            background: '#fff',
                            height: '40px',
                            zIndex: 1,
                            
                        }}
                        className="d-flex align-items-center"
                    
                    >
                        <Box sx={{flex: 1}}></Box>
                        <IconButton
                            sx={{
                              background: '#f1f3f4'  ,
                              mr: 1,
                              mt: '-45px',
                              position: 'absolute',
                              right: 0,
                              '&:hover': {
                                background: '#e7e9ec'
                              }
                            }}
                            onClick={()=>setDrawerOpen(!drawerOpen)}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>

                    </Box> */}
                    
                </Drawer>

                {/* close drawer */}
            
                {/* {
                    drawerOpen === true?
                        ""
                    :
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                height: '85vh',
                                position: 'sticky',
                                top: 0, 
                                left: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    // position: 'absolute',
                                    bottom: '0',
                                    top: '90vh',
                                    // left: CONTENT_DRAWER_WIDTH,
                                    background: 'red',
                                    height: '40px',
                                    zIndex: 1,
                                    
                                }}
                                className="position-sticky d-flex align-items-center"
                                id="boomer"
                            
                            >
                                <Box sx={{flex: 1}}></Box>
                                <IconButton
                                    sx={{
                                        background: '#fff'  ,
                                        mr: 1,
                                        mt: '-45px',
                                        position: 'absolute',
                                        left: 0,
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        '&:hover': {
                                            background: '#e7e9ec'
                                        }
                                    }}
                                    onClick={()=>setDrawerOpen(!drawerOpen)}
                                >
                                    <KeyboardArrowRight />
                                </IconButton>

                            </Box>

                        </Box>
                        
                } */}
            </Box>



            {/* content page */}

            <Box sx={{ width: '100%'}}>
                <Box >
                    {
                        (
                            Object.keys(activeTopicTab).length > 0 && 
                            activeTopicTab.course && 
                            activeTopicTab.chapter && 
                            activeTopicTab.topic &&
                            topicContentEncodedId
                        )?
                            <TopicContentPage 
                                course={activeTopicTab.course}
                                chapter={activeTopicTab.chapter}
                                topic={activeTopicTab.topic}
                                topicTags={activeTopicTab.topicTags}
                                topicContents={topicContents}
                                setTopicContents={setTopicContents}
                                tabValue={tabValue}
                            />
                        :
                        ""
                    }
                </Box>

            </Box>


        </Box>
    )

}