import axios from "axios"
import { API_URL } from "../../Utils/constants"


export const getVimeoVideoDetails = async (url, width, height, color) => {

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    
    let successUrl = 
            API_URL + `video-details/vimeo/`
        
    // axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    return await axios
        .post(successUrl, {
            url: url,
            width: width,
            height: height,
            color: color
        }, headers)
      
}


export const getVDOCipherVideoDetails = async (videoId) => {

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
    
    let successUrl = 
            API_URL + `video-details/vdo-cipher/`
        
    return await axios
        .post(successUrl, {
            videoId: videoId
        }, headers)
      
}





