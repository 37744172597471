import { Box, Tooltip } from '@mui/material'
import React from 'react'
import { capitalizeFirstLetter } from '../../Utils/functions'

export default function TagChip(props) {

    const { label } = props
    return (
        <Tooltip title={label}>
            <Box
                sx={{
                    maxWidth: '50px',
                    py: .2,
                    px: .6,
                    backgroundColor: '#e1ecf4',
                    color: '#1976d2',
                    fontSize: '11px', 
                    height: 'auto',
                    mr: .5,
                    borderRadius: '3px'
                }}
                className="text-truncate"
            >


                {
                    capitalizeFirstLetter((label || "").toLowerCase())
                }

            </Box>
        </Tooltip>
    )


}