import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import { APP_NAME } from '../../Utils/constants'

export default function PageNotFound(props) {
    
    let navigate = useNavigate()
    const handleRedirect = ()=>{
        navigate('/')//redirect home
    }
    
    return (

        <Box style={{paddingTop: '10%'}}>
            
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title> {APP_NAME} | Page Not Found </title>
                <meta name="description" content="Page not found." />
            </Helmet>

            {/* content */}

            <div
                className='container d-flex justify-content-center align-items-center w-100'
                style={{height: '50vh'}}
            >
                <p />
                <div className='d-block d-md-flex'>
                    <Typography 
                        color="primary"
                        variant="h3" 
                        className="fw-bold"
                        sx={{
                            borderRight: {md: '1px solid #d3d3d3', xs: 'none'},
                            pr: 2,
                            mr: 2
                        }}
                    >
                        404
                    </Typography>

                    <div>
                        <Typography 
                            variant="h3"
                            className="fw-bold"
                            sx={{
                                color: '#000',
                            }}
                        >
                            Page not found
                        </Typography>
                        <Typography 
                            variant="subheader"
                            sx={{
                                fontSize: {md: '17px', xs: '14px'}
                            }}
                        >
                            Please check the url or return back home
                        </Typography>
                        <p style={{textAlign:'left'}}> 
                            
                            <Button 
                                variant="contained"
                                sx={{
                                    color: '#000', 
                                    textTransform: 'none',
                                    mt: 1,
                                    boxShadow: 'none',
                                }} 
                                color="primary"
                                onClick={handleRedirect}
                            >
                                Go back home
                            </Button>
                        </p>
                    </div>

                </div>
                
            </div> 
            
        </Box>
    )
}