import axios from "axios";
import { API_URL } from "../../Utils/constants";

export const getAllTopicContentRatings = (courseId, topicContentId, page = 1, pageLimit = 10) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios
        .get(`${API_URL}courses/${courseId}/topic-contents/${topicContentId}/ratings?page=${page}&pageLimit=${pageLimit}`, headers)
            .then(successCallBack)
            .catch(errorCallBack)
      
})


export const getUserSingleTopicContentRating = (tokenId, userId, topicContentId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .get(`${API_URL}students/${userId}/ratings/topic-contents/${topicContentId}`, headers)
            .then(successCallBack)
            .catch(errorCallBack)
      
})


export const getUserRatings = (tokenId, userId, page = 1, pageLimit = 10) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }
      
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .get(`${API_URL}students/${userId}/ratings?page=${page}&pageLimit=${pageLimit}`, headers)
            .then(successCallBack)
            .catch(errorCallBack)
      
})


export const createRating = (tokenId, userId, data) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }    
    let successUrl = 
            API_URL + `students/${userId}/ratings`
        
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .post(successUrl, data, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})


export const updateRating = (tokenId, userId, topicContentId, data) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }    
    let successUrl = 
        API_URL + `students/${userId}/ratings/${topicContentId}`
        
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .put(successUrl, data, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})

export const deleteRating = (tokenId, userId, ratingId) => new Promise((successCallBack, errorCallBack)=>{

    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
    }    
    let successUrl = 
            API_URL + `students/${userId}/ratings/${ratingId}`
        
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .delete(successUrl, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})