
import { createTheme, ThemeProvider } from '@mui/material'
import { useRoutes } from 'react-router'
import { routes } from './Routes/route'
import { SnackbarProvider } from 'notistack'

const theme = createTheme({
  palette: {
    secondary1: {
      main: "#3c195c"
    },
    white: {
      main: '#fff'
    },
    black: {
      main: "#000"
    },
    grey: {
      main: '#E0E5E9'
    },
    darkGrey: {
      main: '#5f6368'
    },
    
  }
})


function App() {
  
  const element = useRoutes(routes())



  return (
    
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={1}>
        <div>
          {element}
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
