import {  ArrowDropDown, ArrowRight, KeyboardArrowDown, KeyboardArrowRight, Lock, PlayArrow } from '@mui/icons-material'
import { Box, Chip, Collapse, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import tinycolor from 'tinycolor2'
import '../../Utils/css/chapterAccordion.css'
import TagChip from '../TagChip'
import { useSelector } from 'react-redux'
import { base64Encode, capitalizeFirstLetter } from '../../Utils/functions'

export default function TopicAccordionDetails(props) {

    const { userSubscription, premiumSubscription, topic, chapter, activeTopicTab, handleSetActiveTopicTab, searchResultTabData } = props
    const { courseTitle } = useParams()
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null
    const navigate = useNavigate()
    const topicContentReducer = useSelector(state => state.topicContentReducer)
    
    const [isOpen, setIsOpen] = useState(false)
    const [topicContents, setTopicContents] = useState([])
    const [sortedTopicContents, setSortedTopicContents] = useState([])

    
    const checkIfUserCanAccessContent = () => {
        return (
            (
                userSubscription && 
                (
                    userSubscription.planId === topic.topic.planId || // user is on premium
                    premiumSubscription._id === userSubscription.planId // user on premium

                )
                
            ) ||
            (
                !userSubscription &&
                premiumSubscription._id !== topic.topic.planId // topic is free so accessible to anyone
            )
        )
    }

    const handleTopicContentClick = (topicContentId) => {
        handleSetActiveTopicTab({
            ...activeTopicTab,
            chapter: chapter,
            topic: topic.topic,
            topicContentId: topicContentId,
            topicTags: topic.tags || []
        })
        // const topicContentId_ = topicContentId?topicContentId:
        //     topicContents.length > 0?topicContents[0]._id:""
        const url = `/course/${courseTitle}/${chapter.title.toLowerCase().replaceAll(' ', '-')}/${topic.topic.title.toLowerCase().replaceAll(' ', '-')}/${btoa(topicContentId)}`
        
        // navigate( url )

        if(user) {
            navigate( url )
        }else {
            navigate(`/auth/login/?redirect=${url}`)
        }
        

    }

    const handleTopicClick = ()=>{
        if(checkIfUserCanAccessContent() === true) {
            setIsOpen((!isOpen))
        } else {
                if(topicContents.length <= 0){
                    setIsOpen((!isOpen))
                    return false                    
                } 
                const url = `/course/${courseTitle}/${chapter.title.toLowerCase().replaceAll(' ', '-')}/${topic.topic.title.toLowerCase().replaceAll(' ', '-')}/${btoa(topicContents[0]._id)}`
            
                navigate( url )
        }
    }


    const isActive = (topicContentId) => {

        //update the route
        if(activeTopicTab.topic && activeTopicTab.chapter && activeTopicTab.topicContentId) {
            
            return activeTopicTab.topicContentId === topicContentId
        }
        return false

    }

    useEffect(() => {
      
        if(topicContentReducer && topicContentReducer.data) {
            setTopicContents(topicContentReducer.data.filter(item=>item.topicId === topic.topic._id))
        }
    
      return () => null
    }, [topicContentReducer, topic])

    useEffect(()=>{
        
        if(sortedTopicContents.length > 0) {
            const isOpen_ = sortedTopicContents.filter(item=>isActive(item._id) === true)

            if(!searchResultTabData.topicContents) {
                setIsOpen(isOpen_.length > 0)
            }
        }

    }, [sortedTopicContents])

    //filtering topic contents
    useEffect(()=>{

        if(searchResultTabData.topicContents) {
            const contents_ = topicContents.filter(item=>searchResultTabData.topicContents.includes(item._id))
            setSortedTopicContents(contents_)
            setIsOpen(contents_.length > 0)
        } else {
            setSortedTopicContents([...topicContents])
        }

    }, [searchResultTabData, topicContents])
    

    // useEffect(()=>{
    //     if(topic._id) {
    //         getTopicTagsByTopic(topic._id)
    //             .then((data)=>{
    //                 if(data.data.data){
    //                     setTopicTags(data.data.data)
    //                     data.data.data.forEach((item)=>{
    //                         //get tags details
    //                         // getSingleTag(item.tagId)
    //                         //     .then((tagResult)=>{
    //                         //         if(selectedTags.filter(item=>item._id === tagResult.data.data._id).length <= 0) {
    //                         //             selectedTags.push(tagResult.data.data)
    //                         //             setSelectedTags(
    //                         //                 [
    //                         //                     ...selectedTags
    //                         //                 ]
    //                         //             )
    //                         //         }
    //                         //     })
    //                         //     .catch(console.log)
    //                     })
    //                 }
    //             })
    //             .catch(console.log)
    //     }
    // }, [topic._id])


    return (
        
        <Box 
            sx={{  pr: .3,  }} 
        >    

            <Box
                className={`d-flex align-items-center chapterAccordion`} 
                sx={{
                    pl: 2,
                    py: .3,
                }}
                onClick={handleTopicClick}
            >

                <Box 
                    className={`d-flex align-items-center justify-content-center`} 
                    sx={{
                        mr: 1
                    }}
                >
                    {
                        checkIfUserCanAccessContent() ?

                            // <Link 
                            //     href="#" 
                            //     onClick={e=>handleTopicContentClick(e, chapter.title.replaceAll(' ', '-'), topic.topic.title.replaceAll(' ', '-'))} 
                            //     sx={{color: '#595959', fontSize: '15px' }}
                            // >
                                isOpen?
                                    <KeyboardArrowDown color="darkGrey" sx={{fontSize: '16px'}} />
                                :
                                <KeyboardArrowRight color="darkGrey" sx={{ fontSize: '16px'}} />
                        :                                                            
                                <Lock color="darkGrey" sx={{fontSize: '12px', ml: .31, mr: .2 }} />
                    }
                </Box>               
                <Typography 
                    variant='span' 
                    sx={{ 
                        color: '#5f6368', 
                        fontSize: '13px', 
                        cursor: 'pointer',
                        "&:hover": {
                            color: '#1976d2'
                        }
                    }}
                >
                    
                    {
                        capitalizeFirstLetter((topic.topic.title || "").toLowerCase())
                    } 
                </Typography>
                <Box style={{flex: 1}}></Box>
                {/* <Typography variant="span">10:30</Typography> */}
                <Box className="d-flex align-items-center" sx={{ ml: 1 }} >
                    {
                        topic.tags?
                            topic.tags.map(item=>
                                <TagChip
                                    key={item.tagId._id} 
                                    label={item.tagId.title}
                                />
                            )
                        :""
                    }
                </Box>
            </Box>
            <Box 
                sx={{
                    mt: .2
                }} 
            >
                   
                <Collapse
                    in={isOpen}
                    timeout={300}
                >
                    {
                        sortedTopicContents.length > 0?
                            sortedTopicContents.map((topicContent)=>(
                            
                                <Box
                                    className={`d-flex align-items-center chapterAccordion ${isActive(topicContent._id)?"activeTopicAccordion":""}`}
                                    onClick={()=>handleTopicContentClick(topicContent._id)}
                                    sx={{
                                        py: .3,
                                        pl: '50px'
                                    }}
                                >
                                                
                                    <Typography 
                                        variant='span' 
                                        sx={{ 
                                            color: isActive(topicContent._id)? '#1976d2':'#5f6368', 
                                            fontSize: '13px', 
                                            cursor: 'pointer',
                                            "&:hover": {
                                                color: '#1976d2'
                                            }
                                        }}
                                    >
                                        
                                        {
                                            capitalizeFirstLetter((topicContent.title || "").toLowerCase())
                                        } 
                                    </Typography>
                                </Box>
                                
                            ))
                        :
                        <Typography variant="span" sx={{ ml: '50px', my: 3, fontSize: '13px', color: '#5f6368' }}>No topic contents found</Typography>
                    }
                </Collapse>
            </Box>
        </Box>
    )

}