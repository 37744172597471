import { Lock, LockOpen, Search } from '@mui/icons-material'
import { Avatar, Box, Button, Card, Chip, Divider, Grid, Link, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { RiseLoader, SyncLoader } from 'react-spinners'
import AlertModalDialog from '../../Components/AlertModal'
import RatingCard from '../../Components/RatingCard'
import RatingModalDialog from '../../Components/RatingModal'
import TabPanel from '../../Components/TabPanel'
import useWindowDimensions from '../../Components/WindowListener'
import { addProgressDetails, getUserTopicContentProgressDetails } from '../../Controller/CourseProgress'
import { getAllChapterTopics, getAllCourseChapters, getAllTopicContents, getSingleTopicContents } from '../../Controller/Courses'
import { getAllTopicContentRatings, getUserSingleTopicContentRating } from '../../Controller/Ratings'
import { getVDOCipherVideoDetails, getVimeoVideoDetails } from '../../Controller/VideoDetailsController'
import { setAllChapters } from '../../Store/Courses/chapterSlice'
import { CONTENT_DRAWER_WIDTH, PROGRESS_STATUS, VIDEO_SOURCE_TYPE } from '../../Utils/constants'
import ContentAlertPlaceHolder from '../../Components/ContentAlertPlaceHolder'
import { capitalizeFirstLetter } from '../../Utils/functions'
import tinycolor from 'tinycolor2'
import TagChip from '../../Components/TagChip'

export default function TopicContentPage(props) {


    const { course, chapter, topic, topicTags, } = props
    const { courseTitle, chapterTitle, topicTitle, topicContentEncodedId  } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    // const courseReducer = useSelector(state => state.courseReducer)
    // const chapterReducer = useSelector(state => state.chapterReducer)
    const topicReducer = useSelector(state => state.topicReducer)
    const {height, width } = useWindowDimensions()
    // const [chapter, setChapter] = useState({})
    // const [course, setCourse] = useState({})
    const [topicContent, setTopicContent] = useState({})
    const [userHasAccess, setUserHasAccess] = useState(false)
    const [isPageLoading, setIsPageLoading] = useState(true)
    const [isVideoLoading, setIsVideoLoading] = useState(false)
    const [isMarkCompleteLoading, setIsMarkCompleteLoading] = useState(false)
    const [videoDetails, setVideoDetails] = useState(null)
    const [userSubscription, setUserSubscription] = useState(null)
    const [premiumSubscription, setPremiumSubscription] = useState({})
    const [topicContentTabValue, setTopicContentTabValue] = React.useState(0)
    const [topicContentProgress, setTopicContentProgress] = useState({})
    const [userReview, setUserReview] = useState(null)
    const [ratingsAndReviews, setRatingsAndReviews] = useState([])
    const vimeoRef = useRef(null)
    const vdoCipherRef = useRef(null)
    let totalWatchedDuration = null
    let startDuration = 0
    const [ratingModalOpen, setRatingModalOpen] = useState(false)
    const [alertModalOpen, setAlertModalOpen] = useState(false)


    const handleRedirectToLogin = () => {
        let url = `/course/${courseTitle}`
        if(chapterTitle && topicTitle) {
            url += `/${chapterTitle}/${topicTitle}/${topicContentEncodedId}`
        }
        navigate(`/auth/login/?redirect=${url}`)
    }




    const handleTopicContentTabChange = (event, newValue) => {
        setTopicContentTabValue(newValue)
    }


    const handleRatingModalOpen = () => {
        setRatingModalOpen(true)
    }

    const handleRatingModalClose = () => {
        setRatingModalOpen(false)
    }
    
    const handleAlertModalOpen = () => {
        setAlertModalOpen(true)
    }

    const handleRAlertModalClose = () => {
        setAlertModalOpen(false)
    }

    function showSnackBar(msg, type = "success") {

    }


    const handleCompleteSubTopic = () => {
        
        // set new data
        setIsMarkCompleteLoading(true)
        addProgressDetails(
            user.token,
            {
                status: PROGRESS_STATUS.COMPLETED,
                endDuration: (topicContent.duration || totalWatchedDuration || 0)
            }, 
            user.user._id,
            topicContentProgress[topicContent._id]._id
        )
        .then((data)=>{
            console.log("new dta", data)
            setIsMarkCompleteLoading(false)
            handleAlertModalOpen()
            setTopicContentProgress({
                ...topicContentProgress,
                status: PROGRESS_STATUS.COMPLETED,
                endDuration: parseFloat(totalWatchedDuration || 0)
            })
        })
        .catch((err)=>{
            console.error(err)
            setIsMarkCompleteLoading(false)
        })
    }

    const handleAddRating = () => {
        handleRatingModalOpen()
    }



    function VimeoIframe (props) {

        const [iframeDets, setIframeDets] = useState({})

        useEffect(()=>{

            //since we want to add custom iframe, we will extract iframe info from the vimeo iframe results
            const iframeDetails = props.videoDetails.substring(videoDetails.html.indexOf('<iframe'), videoDetails.html.indexOf("></iframe>")).replaceAll('<iframe', '')
            const newDets = {}
            iframeDetails.split(" ").forEach((item)=>{
                if(item.indexOf("src") >= 0) {
                    newDets["src"] = (item.split("src=")[1] || "").replaceAll('"', "")
                }else if(item.indexOf("title") >= 0) {
                    newDets["title"] = (item.split("title=")[1] || "").replaceAll('"', "")
                }
                // const itemSplit = item.split("=")
                // newDets[itemSplit[0].trim()] = itemSplit[1] || null
            })
            setIframeDets(newDets)

        }, [props.videoDetails])

        return (
            <iframe
                src={iframeDets.src}
                allow={"autoplay"}
                allowFullScreen
                title={iframeDets.title}
                ref={vimeoRef}
                style={{
                    width: '100%',
                    height: width >= 900 ?'65vh':'300px',
                    background: "#000"
                }}
            ></iframe>
        )
    }

    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    function RatingModal (props) {
        return (
            <RatingModalDialog 
                open={ratingModalOpen}
                handleClose={handleRatingModalClose}
                rating={props.userReview}
                courseId={course._id || null} 
                topicContentId={topicContent?topicContent._id:null}
                chapterId={chapter._id || null}
                topicId={ topic._id || null }
            />
        )
    }

   //load course and chapter details
    // useEffect(() => {
        
    //     if(chapterReducer.data.data.length > 0) {
    //         const chapters_ = chapterReducer.data.data.filter(item=>item.chapter.title.toLowerCase().replaceAll(" ", "-") === chapterTitle.toLowerCase())
    //         if(chapters_.length > 0) {
    //             setChapter(chapters_[0].chapter)
    //         } else {
    //             showSnackBar("Could not load course info", "error")
    //             navigate("/course/"+course.title.toLowerCase().replaceAll(" ", "-"))
    //         }
    //     } else {
    //         if(courseReducer.data.data.length > 0) {
    //             const course_  = courseReducer.data.data.filter(item=>item.title.toLowerCase().replaceAll(" ", "-") === courseTitle.toLowerCase())
    //             if(course_.length > 0) {
    //                 const courseData = course_[0]
    //                 setCourse(courseData)
    //                 if(courseData._id) {
    //                     getAllCourseChapters(courseData._id)
    //                         .then((data)=>{                 
    //                             dispatch(setAllChapters(data.data))
    //                         })
    //                         .catch((err)=>{
    //                             console.log(err)
    //                             showSnackBar("Could not load course info", "error")
    //                             navigate("/course/"+course.title.toLowerCase().replaceAll(" ", "-"))
    //                         })
    //                 }

    //             } else {
    //                 showSnackBar("Invalid course, could not find course.", "error")
    //                 navigate('/')
    //             }
    //         }
    //     }
    
    //     return () => null
    // }, [chapterReducer, dispatch, courseReducer, courseTitle, chapterTitle, course.title])


    // //load topic data
    // useEffect(() => {
        
    //     if(chapter._id) {
    //         getAllChapterTopics(chapter._id)
    //             .then((topics_)=>{
    //                 const searchTopic = topics_.data.data.filter(item=>item.topic.title.toLowerCase().replaceAll(" ", "-") === topicTitle.toLowerCase())
    //                 if(searchTopic.length > 0) {
    //                     setTopic(searchTopic[0].topic)
    //                 } else {
    //                     showSnackBar("Could not load course topic info", "error")
    //                     navigate("/course/"+course.title.toLowerCase().replaceAll(" ", "-"))
    //                 }
    //             })
    //             .catch((err)=>{
    //                 console.log(err)
    //             })
    //     } 
    
    //     return () => null
    // }, [chapter, course.title, topicTitle])

    //get subscription details
    
    useEffect(() => {
        // setIsPageLoading(true)
        if(subscriptionReducer.userSubscription && subscriptionReducer.userSubscription.data && subscriptionReducer.userSubscription.data.length > 0) {
            setUserSubscription(subscriptionReducer.userSubscription.data[0])
        }

        if(subscriptionReducer.data.data.length > 0) {
            const premSubscription = subscriptionReducer.data.data.filter(item=>item.title.toLowerCase() === "premium")
            if(premSubscription.length > 0) setPremiumSubscription(premSubscription[0])
        }
    
      return () => null
    }, [subscriptionReducer])





    //check user subscription details

    useEffect(()=>{
        if(premiumSubscription._id && topic.planId) { //if premium and topic data has loaded
            if(
                (
                    userSubscription && 
                    (
                        userSubscription.planId === topic.planId || // user plan is same as topic plan
                        premiumSubscription._id === userSubscription.planId // user on premium

                    )
                    
                ) ||
                (
                    !userSubscription &&
                    premiumSubscription._id !== topic.planId // topic is free so accessible to anyone
                )
            ) {
                setUserHasAccess(true)

            } else {
                setUserHasAccess(false)
                // navigate('/')
            }
        } 
    }, [userSubscription, premiumSubscription, topic])


    // load topic contents and videos
    useEffect(()=>{



        try{
            if(user && topic._id && userHasAccess === true && topicContentEncodedId) {
                //get topic contents and videos
                setIsPageLoading(true)
                // getAllTopicContents(topic._id)       
                getSingleTopicContents(user.token, topic._id, atob(topicContentEncodedId))       
                .then((data)=>{
                    console.log("topicCOntent", data.data.data)
                    if(data.data.data) {
                        setTopicContent(data.data.data)
                    }
                    setIsPageLoading(false)
                })
                .catch((err)=>{
                    console.log("topicCOntent", err)
                    showSnackBar("Could not load topic contents", "error")
                    setIsPageLoading(false)
                })
            } else {
                setIsPageLoading(false)
            }
        } catch (err) {
            console.log(err)
            setIsPageLoading(false)
        }

    }, [topic, userHasAccess, topicContentEncodedId])
    

    //load video from vimeo or videoicpher if any
    useEffect(()=>{

        if(
            topicContent.videoId &&
            !isVideoLoading
        ) {
            
            setIsVideoLoading(true)
            if((topicContent.videoSource || "").toLowerCase() === VIDEO_SOURCE_TYPE[0].toLowerCase()) {

                getVimeoVideoDetails(
                    topicContent.videoId,
                    "900",
                    "450",
                    "#F3D849"
                )
                    .then((data)=>{
                        console.log(data)
                        setVideoDetails(data.data.data)
                        setIsVideoLoading(false)
                    })
                    .catch((err)=>{
                        console.error(err)
                        setIsVideoLoading(false)
                    })
            } else if((topicContent.videoSource || "").toLowerCase() === VIDEO_SOURCE_TYPE[1].toLowerCase()) {
                
                getVDOCipherVideoDetails(
                    topicContent.videoId
                )
                    .then((data)=>{
                        console.log(data)
                        setVideoDetails(data.data.data)
                        setIsVideoLoading(false)
                    })
                    .catch((err)=>{
                        console.error(err)
                        setIsVideoLoading(false)
                    })
            } else {
                
                setIsVideoLoading(false)
            }
            
        }

    }, [topicContent])

    
    //retrieving and saving student progress / history
    useEffect(() => {
      

        if(user && !topicContentProgress[topicContent._id] ) {


            getUserTopicContentProgressDetails(user.token, user.user._id, topicContent._id, 1, 10)
                .then((results)=>{

                    console.log("results", results)
                    console.log(topicContentProgress)
                    // 
                    if(results.data.data.length > 0) {
                        setTopicContentProgress({
                            ...topicContentProgress,
                            [topicContent._id]: results.data.data[0]
                        })
                    }else {
                        // set new data
                        addProgressDetails(
                            user.token,
                            {
                                studentId: user.user._id,
                                courseId: course._id,
                                topicId: topicContent.topicId,
                                topicContentId: topicContent._id,
                                startDuration: 0,
                                endDuration: 0
                            }, 
                            user.user._id 
                        )
                        .then((data)=>{
                            console.log("new dta", data)
                            setTopicContentProgress(data.data.data)
                        })
                        .catch(console.error)
                    }

                })
                .catch((err)=>{
                    console.log("results", err)
                })

        }
    
      return () => null
    }, [topicContent, topicContentProgress, user])


    useEffect(() => {
      
        
        if(vimeoRef && vimeoRef.current) {
            startDuration = 0
            const vimeoIframe = window.document.querySelector('iframe')
            vimeoIframe.style.width = "100%"
            vimeoIframe.style.maxWidth = "100%"
            vimeoIframe.style.height = width >= 900 ?'65vh':'300px'
            vimeoIframe.style.border = 0
            vimeoIframe.style.background = "#000"
            const player = new window.Vimeo.Player(vimeoIframe)
            player.on('play', function() {
                console.log('Played the video', player)
                player.getCurrentTime()
                .then((data)=>{
                    startDuration = data
                })
            })
            player.on('ended', updateProgress)

            function updateProgress() {
                player.getCurrentTime().then(function (data) {
                    //save progress to db
                    totalWatchedDuration = parseFloat(startDuration || 0) + parseFloat(data || 0)
                    addProgressDetails(
                        user.token,
                        {
                            startDuration: parseFloat(startDuration),
                            endDuration: totalWatchedDuration
                        }, 
                        user.user._id,
                        topicContentProgress[topicContent._id]._id
                    )
                    .then((_data)=>{
                        setTopicContentProgress({
                            ...topicContentProgress,
                            startDuration: parseFloat(startDuration),
                            endDuration: parseFloat(totalWatchedDuration || 0)
                        })
                    })
                    .catch(console.error)
                });

            }
        }else {
            console.log("Video is not playing yyy")
        }


        if(vdoCipherRef && vdoCipherRef.current) {

            let player = window.VdoPlayer.getInstance(vdoCipherRef.current)


            
            // player.api.getMetaData().then(function (data) {
            //     totalWatchedDuration = data.duration
            // });

            // All DOM API supports on player.video
            startDuration = 0
            player.video.addEventListener("play", function () {
                startDuration = player.video.currentTime
            })          
      
            // All DOM API supports on player.video
            player.video.addEventListener("pause", updateProgress)
            player.video.addEventListener("end", updateProgress)

            function updateProgress() {
                player.api.getTotalCovered().then(function (data) {
                    //save progress to db
                    totalWatchedDuration = parseFloat(startDuration || 0) + parseFloat(data || 0)
                    addProgressDetails(
                        user.token,
                        {
                            startDuration: parseFloat(startDuration),
                            endDuration: totalWatchedDuration
                        }, 
                        user.user._id,
                        topicContentProgress[topicContent._id]._id
                    )
                    .then((_data)=>{
                        setTopicContentProgress({
                            ...topicContentProgress,
                            startDuration: parseFloat(startDuration),
                            endDuration: parseFloat(totalWatchedDuration || 0)
                        })
                    })
                    .catch(console.error)
                });

            }
        } else {
            console.log("Video is not playing")
        }
        return () => null
    }, [vimeoRef.current, vdoCipherRef.current, videoDetails])
    
    
    React.useEffect(() => {
      
        if(user && topicContent && course){

            
            
            getAllTopicContentRatings(course._id, topicContent._id, 1, 10)
                .then((data)=>{
                    if(data.data && data.data.data && data.data.data.length > 0) {
                        setRatingsAndReviews(data.data.data)
                    }else {
                        setRatingsAndReviews([...[]])
                    }
                })
                .catch((err)=>{
                    setRatingsAndReviews([...[]])
                    console.log("ratings error", err)
                })

            getUserSingleTopicContentRating(user.token, user.user._id, topicContent._id)
            .then((data)=>{
                if(data.data && data.data.data && data.data.data.length > 0) {
                    setUserReview(data.data.data[0])
                }else {
                    setUserReview(null)
                }
            })
            .catch((err)=>{
                console.log(err)
                setUserReview(null)
            })


        }

        return () => null
    }, [topicContent, topicContentProgress])
   


    return (
        <Box >

            
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title> {course.title || courseTitle} | {chapter.title || ""} | {topic.title || ""} | Continue Learning  </title>
                <meta name="description" content={ topic.description || "" } />
            </Helmet>

            {/* content */}  

            {
                isPageLoading?
                    <Box className="d-flex justify-content-center align-items-center" sx={{height: '400px'}}>
                        
                        <RiseLoader
                            color={"#F3D849"}
                            loading={isPageLoading}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </Box>
                :

                userHasAccess === false?
                    <Box sx={{ m: 3, mt: '45px', pt: 3 }}>
                        <Card >
                            <ContentAlertPlaceHolder
                                icon={
                                    <Lock color="white" sx={{fontSize: '50px'}} />
                                }
                                title={"Premium content detected."}
                                description={"Please upgrade your plan to access unlimited access to all premium content anywhere anytime."}
                                actionHandler={()=>navigate(`/auth/login/`)}
                                actionTxt={"Upgrade plan"}
                            />
                        </Card>
                    </Box>


                :
                !user?
                    <Box sx={{ m: 3, mt: '45px', pt: 3 }}>
                        <Card >
                            <ContentAlertPlaceHolder
                                icon={
                                    <LockOpen color="white" sx={{fontSize: '50px'}} />
                                }
                                title={"Oops! Unauthenticated request."}
                                description={"Please login to view all our free to access contents. It's actually free to sign in. ."}
                                actionHandler={handleRedirectToLogin}
                                actionTxt={"Log in"}
                            />
                        </Card>
                    </Box>


                :
                
                    <Box sx={{ position: 'relative' }} >
                        
                        <Box 
                            className="d-flex"
                            sx={{ my: 3, mx: '65px' , mt: '45px', pt: 3 }}
                        >
                            <Card sx={{ width: '100%', minHeight:'83vh', p: 4,  }}>
                                
                                <Box>                                        
                                    <Grid container spacing={1}>
                                        
                                        {
                                            ((videoDetails && (videoDetails.html || videoDetails.otp)) || isVideoLoading)?
                                            
                                                <Grid item xs={12} md={12}>
                                                    {
                                                        isVideoLoading?
                                                            <Box className="d-flex justify-content-center align-items-center" sx={{height: '400px'}}>
                                                                
                                                                <RiseLoader
                                                                    color={"#F3D849"}
                                                                    loading={isVideoLoading}
                                                                    size={10}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                />
                                                            </Box>
                                                        :
                                                            <Box >
                                                                
                                                                <Box 
                                                                    className="d-flex align-items-center"
                                                                >

                                                                    <Box 
                                                                        className="d-block"
                                                                        sx={{ mb: 1 }}
                                                                    >
                                                                        
                                                                        <Box className="d-flex align-items-center" sx={{ mb: 1 }} >
                                                                            {
                                                                                topicTags
                                                                                ?
                                                                                    topicTags
                                                                                        .map(item=>
                                                                                            <TagChip 
                                                                                                key={item.tagId._id} 
                                                                                                label={item.tagId.title}
                                                                                            />
                                                                                        )
                                                                                :""
                                                                            }
                                                                        </Box>
                                                                    
                                                                        <Box className="d-flex align-items-center">
                                                                            <Typography variant="h4" gutterBottom  >
                                                                                {
                                                                                    capitalizeFirstLetter(
                                                                                        (topicContent.title || "").toLowerCase()
                                                                                    )
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{flex: 1}}></Box>
                                                                    {
                                                                        topicContentProgress && 
                                                                        topicContentProgress[topicContent._id] &&
                                                                        topicContentProgress[topicContent._id].status === PROGRESS_STATUS.COMPLETED?
                                                                            ""
                                                                        :
                                                                            <Button
                                                                                color="primary"
                                                                                variant="outlined"
                                                                                size="medium"
                                                                                onClick={handleCompleteSubTopic}
                                                                                sx={{
                                                                                    mr: 1,
                                                                                    textTransform: 'none',
                                                                                    borderColor: '#dadce0',
                                                                                    '&:hover': {
                                                                                        borderColor: '#dadce0',
                                                                                    }
                                                                                }}
                                                                                disabled={isMarkCompleteLoading}
                                                                            >
                                                                                
                                                                                {
                                                                                    isMarkCompleteLoading?
                                                                                        <SyncLoader
                                                                                            size={6}
                                                                                            loading={isMarkCompleteLoading}
                                                                                            color='#000'
                                                                                        />
                                                                                    :"Mark as complete"
                                                                                }
                                                                                
                                                                                
                                                                            </Button>
                                                                            
                                                                    }
                                                                    {
                                                                        (userReview || (topicContentProgress && 
                                                                        topicContentProgress[topicContent._id] &&
                                                                        topicContentProgress[topicContent._id].status === PROGRESS_STATUS.COMPLETED))?
                                                                            

                                                                            <Button
                                                                                color="primary"
                                                                                variant="outlined"
                                                                                size="medium"
                                                                                onClick={handleAddRating}
                                                                                sx={{
                                                                                    // color: '#fff',
                                                                                    textTransform: 'none',
                                                                                    borderColor: '#dadce0',
                                                                                    '&:hover': {
                                                                                        borderColor: '#dadce0',
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {
                                                                                    userReview?
                                                                                        "Update rating"
                                                                                    :
                                                                                        "Leave a rating"
                                                                                }
                                                                                
                                                                            </Button>
                                                                                
                                                                        :""
                                                                    }
                                                                </Box>

                                                                <Divider
                                                                    color="grey"                                                                                    
                                                                    sx={{
                                                                        mb: 3
                                                                    }}
                                                                />
                                                                
                                                                {
                                                                    videoDetails.html? //vimeo video
                                                                        // <VimeoIframe videoDetails={videoDetails.html} />
                                                                        <div ref={vimeoRef} dangerouslySetInnerHTML={{ __html: videoDetails.html }} />
                                                                    :
                                                                    <iframe
                                                                        ref={vdoCipherRef}
                                                                        title={topicContent.title}
                                                                        src={`https://player.vdocipher.com/v2/?otp=${videoDetails.otp}&playbackInfo=${videoDetails.playbackInfo}`}
                                                                        style={{
                                                                            border:0,
                                                                            maxWidth: '100%',
                                                                            // position: 'absolute',
                                                                            // top: 0, 
                                                                            // left: 0,
                                                                            height: width >= 900 ?'65vh':'300px',
                                                                            width: '100%'
                                                                        }}
                                                                        allowfullscreen="true"
                                                                        allow="encrypted-media"
                                                                    >
                                                                    </iframe>
                                                                }


                                                                <Box>
                                                                    <Box >
                                                                        <Tabs 
                                                                            value={topicContentTabValue} 
                                                                            onChange={handleTopicContentTabChange} 
                                                                            aria-label="basic tabs example"
                                                                            indicatorColor="primary"
                                                                            // textColor="black"
                                                                            sx={{mb: 1}}
                                                                        >
                                                                            <Tab label="Description" {...a11yProps(11)} sx={{textTransform: 'none'}} />
                                                                            <Tab label="Ratings & Reviews" {...a11yProps(12)} sx={{textTransform: 'none'}} />
                                                                        </Tabs>
                                                                        <TabPanel  value={topicContentTabValue} index={0}>
                                                                            
                                                                            <Box className="container" sx={{
                                                                                px: 1, 
                                                                                pb: 3,
                                                                                mt: 1, 
                                                                                // height: width >= 500 ?'72vh':'auto', 
                                                                                // overflowY: width > 900?'scroll':'inherit'
                                                                            }}>
                                                                                <div dangerouslySetInnerHTML={{ __html: topicContent.description }} />
                                                                            </Box>
                                                                        </TabPanel>
                                                                        <TabPanel value={topicContentTabValue} index={1}>
                                                                            <Grid container spacing={1}>
                                                                                {
                                                                                    ratingsAndReviews.map((rating)=>(
                                                                                        
                                                                                        <Grid 
                                                                                            item 
                                                                                            xs={12} 
                                                                                            md={12}
                                                                                            key={rating._id}
                                                                                        >
                                                                                            <RatingCard
                                                                                                rating={rating}
                                                                                            />
                                                                                        </Grid>
                                                                                    ))
                                                                                }
                                                                            </Grid>
                                                                        </TabPanel>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                    }
                                                </Grid>
                                            :
                                                ""
                                        }
                                            {/* <Grid item xs={12} md={7}>
                                                <Box >
                                                    <iframe className="rounded" style={{width: '100%', height: '72vh'}} src="https://www.youtube.com/embed/cpP-fCo8Dn4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                </Box>   
                                            </Grid> */}
                                    </Grid>
                                </Box>
                            </Card>
                            

                            {
                                topicContent && Object.keys(topicContent).length > 0?

                                
                                    <Box 
                                        sx={{ 
                                            width: '350px', 
                                            display: {md: 'block', xs: 'none'},
                                            height:'80vh',
                                            ml: 3
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                borderLeft: '4px solid #039be5',
                                                px: 1
                                            }}
                                        >
                                            <Typography
                                                variant="span"
                                                className='fw-bold'
                                                color="darkGrey"
                                                sx={{
                                                    fontSize: '13px',
                                                }}
                                            >
                                                On this page
                                            </Typography>
                                            
                                            <Box
                                                sx={{
                                                    mt: .7
                                                }}
                                            >

                                                {

                                                    ["Introduction", "Get started", "Environment configs", "Python IDE's", "Table of contents", "Installation", "Windows configuration", "Syntax", "Conclusion", "Awards", "Bonus", "Quiz" ]
                                                        .map((item)=>(
                                                            <Link
                                                                href="#"
                                                                sx={{
                                                                    fontSize: '13px',
                                                                    display: 'block',
                                                                    mb: .7,
                                                                    textDecoration: 'none',
                                                                    color: '#000',
                                                                    "&:hover": {
                                                                        color: '#185abc'
                                                                    }
                                                                }}
                                                                key={item}
                                                            >
                                                                {
                                                                    item
                                                                }
                                                            </Link>

                                                        ))

                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                :""
                            }
                        </Box>
                    </Box>
            }


            <RatingModal userReview={userReview} />
            <AlertModalDialog 
                open={alertModalOpen}
                handleClose={handleAlertModalOpen}
                handler={handleRatingModalOpen}
            />
        </Box>
    )

}