import { Grid, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { getSingleChapterTopic, getSingleTopicContents } from '../../Controller/Courses'
import { PROGRESS_STATUS } from '../../Utils/constants'

export default function CourseProgressCard(props) {
    
    const { progressDet } = props
    const [topicContent, setTopicContent] = useState({})
    const [topic, setTopic] = useState({})

    

    useEffect(() => {
      
        getSingleTopicContents(progressDet.topicId, progressDet.topicContentId)
            .then((data)=>{
                setTopicContent(data.data.data)
                getSingleChapterTopic(data.data.data.chapterId, progressDet.topicId)
                    .then((results)=>{
                        setTopic(results.data.data || {})
                    })
                    .catch(console.log)
            })
            .catch(console.log)
        return () => null
    }, [progressDet])
    


    return (
        
        <Box sx={{ mt: 1, }}>
            <Grid container>
                <Grid item xs={3.3} md={3}>
                    <Box sx={{p: 1, m: 1, borderRight: '1px solid #d3d3d3'}}>
                        <Typography variant="h6" sx={{fontSize: '15px', color: '#000'}} className="text-truncate">
                            {
                                (new Date(parseFloat(progressDet.updatedAt || 0 )).getDate() === new Date().getDate())?
                                    "Today"
                                :
                                new Date(parseFloat(progressDet.updatedAt || 0 ))                                                    
                                    .toLocaleString("en-US", { weekday: 'long' })
                            }
                        </Typography>
                        <Typography
                            sx={{color: 'rgba(0,0,0,.6)', fontSize: '13px'}}
                        >
                            {
                                
                                    new Date(parseFloat(progressDet.updatedAt || 0 ))                                                    
                                        .toLocaleString("en-US", { day: 'numeric', month: 'short', year: 'numeric',})
                            }
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={8.7} md={9}>
                    <Box >
                        <Grid container>
                            {/* <Grid item xs={3}>
                                <Box sx={{p: 1, m: 1, }}>
                                    <Typography variant="h6" sx={{fontSize: '12px', }}>
                                        10:00 AM
                                    </Typography>
                                    <Typography variant="h6" sx={{fontSize: '12px', }}>
                                        11:00 AM
                                    </Typography>
                                </Box>

                            </Grid> */}
                            <Grid item xs={12}>

                                <Box className="d-flex w-100">
                                    <Box sx={{p: {md: 3, xs: 1}}} className="d-flex align-items-center justify-content-center">
                                        <span 
                                            style={{ 
                                                background: progressDet.status === PROGRESS_STATUS.IN_PROGRESS?'red':'green', 
                                                width: '10px', 
                                                height: '10px', 
                                                borderRadius: '50%' 
                                            }}
                                        >

                                        </span>
                                    </Box>
                                    <Box className="d-block d-md-flex w-100" sx={{p: 1, m: 1,}}>
                                        
                                        <Box >
                                            <Typography variant="h6" sx={{fontSize: '16px', fontWeight: 'bold'}}>
                                                {
                                                    topicContent.title || ""
                                                }
                                            </Typography>
                                            <Typography
                                                sx={{color: 'rgba(0,0,0,.6)', fontSize: '15px'}}
                                            >
                                                {
                                                    topic.title || ""
                                                }
                                            </Typography>
                                        </Box>
                                        <Box sx={{flex: 1}}></Box>
                                        <Box>
                                            <Tooltip
                                                title={"Total duration of video watched"}
                                            >
                                                <Typography
                                                    sx={{color: 'rgba(0,0,0,1)', fontSize: '15px'}}
                                                >
                                                    { progressDet.endDuration || 0 } mins
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}