import { createSlice } from '@reduxjs/toolkit'

export const chapterSlice = createSlice({
  name: 'chapterSlice',
  initialState: {
    data: {
      data: []
    }
  },
  reducers: {
    setAllChapters: (state, action) => {
      state.data = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setAllChapters } = chapterSlice.actions

export default chapterSlice.reducer