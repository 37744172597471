import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router'
import { RiseLoader } from 'react-spinners'
import NavBar from '../../Components/NavBar'
import { signUpUser } from '../../Controller/Auth'
import { getAllCourses } from '../../Controller/Courses'
import { getAllSubscriptionPlans, getUserSubscriptionPlan } from '../../Controller/Subscriptions'
import { setAllCourses } from '../../Store/Courses/courseSlice'
import { setAllSubscriptions, setUserSubscription } from '../../Store/Subscriptions/subscriptionSlice'
import { GOOGLE_CLIENT_ID } from '../../Utils/constants'

export default function MainTheme(props) {

    const { showNavBar, showBoxShadow } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let user = localStorage.getItem('sessionUser')
    user = user?JSON.parse(user):null
    const [showAppBar, setShowAppBar] = useState( true )
    const [isRefreshGoogleTokenId, setIsRefreshGoogleTokenId] = useState( false )
    
    


    useEffect(()=>{
        setShowAppBar(showNavBar != null?showNavBar:true)
    }, [showNavBar])

    useEffect(() => {
        
        getAllCourses()
            .then((data)=>{
                dispatch(setAllCourses(data.data))
            })
            .catch((err)=>{
                console.log(err)
            })

        getAllSubscriptionPlans()
            .then((data)=>{
                console.log(data)
                dispatch(setAllSubscriptions(data.data))
                console.log(user)
                if(!user) {
                    console.log(data.data)
                    dispatch(setUserSubscription(data.data.data.filter(item=>item.title.toUpperCase() === "FREE")))                    
                } else {
                    //get user subscription
                    // console.log("user", user.token)
                    getUserSubscriptionPlan(user.token, user.user._id)
                        .then((_data)=>{
                            dispatch(setUserSubscription(_data.data.data))
                        })
                        .catch((err)=>{
                            console.log(err.response)
                        })
                }
            })
            .catch((err)=>{
                console.log(err)
            })
    
        return () => null
    }, [dispatch, user])

    useEffect(()=>{
        
        function handleCredentialResponse(response) {
            if(response.credential) {
                setIsRefreshGoogleTokenId(true)
                // console.log("auto sign in", response)
                // console.log("Encoded JWT ID token: " + response.credential)
                const localUser = {
                    token: response.credential
                }
                signUpUser(response.credential)
                    .then((resp)=>{
                        // console.log("auto sign in", resp )
                        localUser["user"] = typeof resp.data.data === 'object'?resp.data.data:{message: resp.data.data}
                        localStorage.setItem("sessionUser", JSON.stringify(localUser))
                        setIsRefreshGoogleTokenId(false)              
                        

                    })
                    .catch((err)=>{
                        console.log("login error", err)
                        setIsRefreshGoogleTokenId(false)
                        navigate('/auth/login')
                    })
            }else {
                // alert("Sorry could not login.")
                navigate('/auth/login')
            }
        }
        window.handleCredentialResponse = handleCredentialResponse
    }, [])
    

    
    return (
        <Box>
            {
                showAppBar?
                    <NavBar navColor="#fff" textColor="#000" />
                :
                    ""
            }
            
            {
                isRefreshGoogleTokenId? //if we are refreshing google tokens then dont show page
                    <Box                
                        className="d-flex justify-content-center align-items-center"
                        sx={{height: '90vh'}}
                    >
                        <RiseLoader
                            color={"#F3D849"}
                            loading={isRefreshGoogleTokenId}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"

                        />
                    </Box>
                :
                <Box sx={{ mt: '70px' }} >
                    <Outlet />
                </Box>
            }
            

            <div 
                id="g_id_onload"
                data-client_id={`${GOOGLE_CLIENT_ID}`}
                data-auto_select="true"
                // data-login_uri={`${window.location.origin}/auth/login`}
                data-callback="handleCredentialResponse"
            >
            </div>
        </Box>
    )

}