import axios from "axios";
import { API_URL } from "../../Utils/constants";

export const signUpUser = (tokenId) => new Promise((successCallBack, errorCallBack)=>{
    const headers = { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenId}`
    axios
        .post(`${API_URL}auth/signup`, {}, headers)
        .then(successCallBack)
        .catch(errorCallBack)
      
})
