
import { DonutSmall, Search, Star } from '@mui/icons-material'
import { Avatar, Button, Card, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RiseLoader } from 'react-spinners'
import CourseProgressCard from '../../Components/CourseProgressCard'
import { getUserProgressDetails, getUserProgressStats } from '../../Controller/CourseProgress'
import { APP_NAME } from '../../Utils/constants'
import { capitalizeFirstLetter, getSubItemPriceId } from '../../Utils/functions'
import profile_bg from '../../Utils/Images/profile-bg.jpg'

//ref https://www.uplabs.com/posts/student-profile-ui-design


// user profile page

export default function ProfilePage(props) {

    
    let user = localStorage.getItem("sessionUser")
    user = user?JSON.parse(user):null
    const navigate = useNavigate()
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    const [userSubscription, setUserSubscription] = useState({})
    const [userSubscriptionPlan, setUserSubscriptionPlan] = useState({})
    const [progressStats, setProgressStats] = useState({})
    const [progressDetails, setProgressDetails] = useState([])
    const [isProgressLoading, setIsProgressLoading] = useState(false)

    
    if(!user) {
        navigate('/auth/login/?redirect=/pages/profile')
    }

    useEffect(() => {
        if(
            subscriptionReducer && 
            subscriptionReducer.userSubscription && 
            subscriptionReducer.userSubscription.data && 
            subscriptionReducer.userSubscription.data.length > 0
        ) {
            setUserSubscription(subscriptionReducer.userSubscription.data[0])
            const userPlan = subscriptionReducer.data.data.filter(item=>item._id === subscriptionReducer.userSubscription.data[0].planId)
            setUserSubscriptionPlan(userPlan.length?userPlan[0]:{})
        }
    
      return () => null
    }, [subscriptionReducer])

    useEffect(() => {
      
        if(user) {
            setIsProgressLoading(true)
            getUserProgressDetails(user.token, user.user._id, 1, 10)
                .then((data)=>{
                    setIsProgressLoading(false)
                    setProgressDetails([...data.data.data])
                })
                .catch((err)=>{
                    console.log(err)
                    setIsProgressLoading(false)
                })

            getUserProgressStats(user.token, user.user._id)
                .then((data)=>{

                    setProgressStats(data.data.data || {})
                })
                .catch((err)=>{
                    console.log(err)
                })
        }
    
        return () => null
    }, [])
    


    return (
        <Box sx={{mb: 3, pb: 2}}>
            
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title> {user.user.fName || APP_NAME} {user.user.lName || ""} | { APP_NAME} | Profile Page </title>
                <meta name="description" content="View your student profile and get to see what you've achieved as well as your personal data." />
            </Helmet>

            {/* content */}

            <Box
                sx={{
                    background: "#fff"
                }}
            >
                <Box 
                    sx={{
                        height: {md: '250px', xs: '330px'},
                        backgroundImage: `url(${profile_bg})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            backgroundColor: 'rgba(0,0,0,.6)',
                            top: 0,
                            left: 0,
                            width: '100%',
                            bottom: 0,
                            zIndex: 1
                        }}
                    ></Box>
                </Box>
                <Box
                    sx={{
                        // height: '80px'
                        py: 1
                    }}
                >

                    <Box
                        className="container"
                    >


                        <Box className="d-block d-md-flex" sx={{ mb: 1 }} >
                            
                            <Box sx={{position: 'relative', mr: 2, width: {md: '120px', xs: '100%'} }} >
                                <Avatar
                                    variant="square"
                                    sx={{
                                        width: '120px',
                                        height: '120px',
                                        border: "4px solid #fff",
                                        position: 'absolute',
                                        left: {md: 0, xs: 'calc(50% - 60px)'},
                                        top: '-60px',
                                        zIndex: 11,
                                    }}
                                    alt={(user && user.user)?((user.user.fName || "Anonymous") + " " + (user.user.lName || "User")):"Anonymous User"} 
                                    src={(user && user.user)?user.user.profileImg:null}
                                    className="d-flex align-items-center justify-content-center"

                                >
                                    {
                                        ((user && user.user)?user.user.fName:"Anonymous").substring(0,1)
                                    }
                                </Avatar>
                            </Box>

                            <Box
                                sx={{
                                    borderRight: {md: '1px solid #e7ebf0', xs: 'none'},
                                    px: 1,
                                    width: {md: '300px', xs: '100%'},
                                }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Box sx={{
                                    mt: {md: 0, xs: '80px'}}}>
                                    <Typography
                                        variant="h5"
                                        className="fw-bold"
                                        sx={{
                                            width: '200px',
                                            textAlign: {md: 'left', xs:'center'},
                                        }}
                                    >
                                        {(user && user.user)?((user.user.fName || "Anonymous") + " " + (user.user.lName || "User")):"Anonymous User"}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            textAlign: {md: 'left', xs:'center'},
                                        }}
                                    >
                                        {
                                            user.user.city || ""
                                        }
                                        
                                        {
                                            user.user.country?", " + user.user.country:""
                                        }
                                        {/* {(user && user.user)?((user.user.fName || "Anonymous") + " " + (user.user.lName || "User")):"Anonymous User"} */}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box
                                
                                sx={{
                                    borderRight: {md: '1px solid #e7ebf0', xs: 'none'},
                                    px: 1,
                                    width: {md: '300px', xs: '100%'},
                                    mt: {md: 0, xs: 1}
                                }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Box>
                                    <Box 
                                        className="d-flex" 
                                        sx={{
                                            justifyContent: {md: 'start', xs:'center'},
                                        }}
                                    >
                                        <Star color="primary" sx={{mr: 1}} /> {progressStats.totalTopicContentsLearned || 0} Sub topics completed
                                    </Box>
                                    <Box 
                                        className="d-flex"
                                        sx={{
                                            justifyContent: {md: 'start', xs:'center'},
                                        }}
                                    >
                                        <DonutSmall color="secondary" sx={{mr: 1}} /> {progressStats.progress || "0%"} progress
                                    </Box>
                                </Box>
                            </Box>
                            
                            <Box
                                
                                sx={{
                                    borderRight: {md: '1px solid #e7ebf0', xs: 'none'},
                                    px: 1,
                                    width: {md: '330px', xs: '100%'},
                                    mt: { md: 0, xs: 1 }
                                }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Box>
                                    <Box 
                                        sx={{
                                            justifyContent: {md: 'start', xs:'center'},
                                        }}
                                        className="d-flex"
                                    >
                                        Status: 
                                        
                                        <Typography 
                                            variant="span" 
                                            className="rounded-pill"
                                            sx={{
                                                backgroundColor: '#F3D849',
                                                fontSize: '11px',
                                                py: 0.5,
                                                px: 1,
                                                color: '#fff',
                                                ml: 1,
                                            }}
                                        >
                                            {
                                                capitalizeFirstLetter((userSubscription.status || "Active").toLowerCase())
                                            }
                                        </Typography>
                                    </Box>
                                    <Box 
                                        sx={{
                                            justifyContent: {md: 'start', xs:'center'},
                                        }}
                                        className="d-flex"
                                    >
                                        Current plan: 
                                        
                                        <Typography 
                                            variant="span" 
                                            className="rounded-pill"
                                            sx={{
                                                ml: 1,
                                                fontSize: '15px'
                                            }}
                                        >
                                            {
                                                capitalizeFirstLetter( (userSubscriptionPlan.title || "Free").toLowerCase() )
                                            }
                                            {
                                                subscriptionReducer && 
                                                subscriptionReducer.data && 
                                                subscriptionReducer.data.data && 
                                                subscriptionReducer.data.data.length > 0 && 
                                                userSubscription.itemPriceId === getSubItemPriceId(subscriptionReducer.data.data[1], false)?
                                                    " ( yearly )"
                                                :
                                                    ((userSubscriptionPlan.title || "Free").toLowerCase() === "free")?
                                                        ""
                                                    :
                                                        " ( monthly )"
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                
                                sx={{
                                    px: 1,
                                    width: {md: '300px', xs: '100%'},
                                    my: { md: 0, xs: 2 }
                                }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    sx={{
                                        boxShadow: 'none',
                                        textTransform: 'none',
                                    }}
                                    onClick={()=>navigate('/pages/settings')}
                                >
                                    Edit profile
                                </Button>
                            </Box>

                        </Box>

                    </Box>

                </Box>
            </Box>


            
            <Box className="container">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Card
                            
                            sx={{
                                width: '100%',
                                background: '#fff',
                                boxShadow: 'none',
                                mt: 1
                            }}
                        >
                            <Box
                                sx={{
                                    borderBottom: "1px solid #e7ebf0",
                                    py: 2,
                                    px: 2,
                                }}
                                className='rounded'
                            >

                                <Typography
                                    variant="body2"
                                >
                                    PROGRESS HISTORY
                                </Typography>

                            </Box>


                                {
                                    isProgressLoading?
                                        
                                        <Box className="d-flex justify-content-center align-items-center" sx={{height: '200px'}}>
                                            <RiseLoader
                                                color={"#F3D849"}
                                                loading={isProgressLoading}
                                                size={10}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </Box>
                                    :
                                    
                                        progressDetails.length > 0?
                                            <Box sx={{px: 1}}>

                                                {
                                                    progressDetails.map(item=><CourseProgressCard key={item._id} progressDet={item} />)
                                                }
                                                
                
                                            </Box>
                                        :
                                        
                                            <Box className="d-flex justify-content-center align-items-center" sx={{height:'250px'}}>
                                                <Box sx={{width: {md: '40%', xs: '100%'}}}>
                                                    <Box className="d-flex justify-content-center align-items-center">
                                                        <Avatar variant="square" sx={{width: '50px', height: '50px'}}>
                                                            <Search color="white" sx={{fontSize: '25px'}} />
                                                        </Avatar>
                                                    </Box>
                                                    <Typography variant="h6" className="fw-bold text-center" sx={{mt: 2}} >
                                                        0 progress history found.
                                                    </Typography>
                                                    <Typography variant="body2" className="text-center" sx={{mt: 1}}>
                                                        Start learning today to view your progress history here.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                }
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>

                    </Grid>
                </Grid>
            </Box>
                


        </Box>
    )

}