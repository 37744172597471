import { Card, CardContent, CardHeader, FormControl, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { RiseLoader, SyncLoader } from 'react-spinners'
import { createUserSubscriptionPlan, upgradeOrDowngradeUserSubscriptionPlan } from '../../Controller/Subscriptions'
import { API_URL, inputStyles } from '../../Utils/constants'
import { capitalizeFirstLetter, getSubItemPriceAmount, getSubItemPriceId } from '../../Utils/functions'
import { Close } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { getStripePaymentIntent } from '../../Controller/Payments'

export default function CheckoutForm(props) {

    const { clientSecret }  = props
    const { planTitle, itemPriceId } = useParams()
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const userObj = localStorage.getItem("sessionUser")
    const user = userObj?JSON.parse(userObj):null

    const stripe = useStripe()
    const elements = useElements()
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [paymentIntent_, setPaymentIntent_] = useState({})
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    const courseReducer = useSelector(state => state.courseReducer)
    const [userSubscription, setUserSubscription] = useState({})
    const [selectedSubscription, setSelectedSubscription] = useState({})



    // const CARD_ELEMENT_OPTIONS = {
    //     style: {
    //     base: {
    //         width: '100%',
    //         color: "#32325d",
    //         fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //         fontSmoothing: "antialiased",
    //         padding: '2px',
    //         // backgroundColor: '#f4f5fa',
    //         fontSize: "16px",
    //         "::placeholder": {
    //         color: "#000",
    //         },
    //     },
    //     invalid: {
    //         color: "#fa755a",
    //         iconColor: "#fa755a",
    //     },
    //     },
    // }
  
    

    const urlRedirectClientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    )


    if(!planTitle || !itemPriceId || planTitle.toLowerCase() === "free") {
        navigate('/404')
    }

    const getPlanPrice = () => ((itemPriceId && itemPriceId.toString().toUpperCase() === "MONTHLY")?getSubItemPriceAmount(selectedSubscription, true):getSubItemPriceAmount(selectedSubscription, false))
    
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()

        if (!stripe || !elements) {
            showSnackBar("Stripe.js has not yet loaded. Please refresh page and try again.", "error")
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        
        if (!clientSecret) {
            showSnackBar("Missing stripe payment settings. Please refresh page and try again.", "error")
            return
        }

        setIsLoading(true)





        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            client_secret: clientSecret,
            confirmParams: {
                return_url: `${window.location.protocol}//${window.location.host}/pages/checkout/${planTitle}/${itemPriceId}/`,
            },
        })

        
        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            showSnackBar(error.message, "error")
            setIsLoading(false)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            setIsLoading(false)
            showSnackBar("An unexpected error occurred.", "error")
            
        }

            
            

        
    }
  

    function handlePaymentSuccess() {

        setIsUpdateLoading(true)
        //call api to add subscription
        
        const subItemPriceId = getSubItemPriceId(selectedSubscription, itemPriceId && itemPriceId.toString().toUpperCase() === "MONTHLY")
        // const actionFn = (!userSubscription || Object.keys(userSubscription).length <= 0)?
        //     createUserSubscriptionPlan(user.token, user.user._id, subItemPriceId, selectedSubscription._id, paymentIntent_.id, paymentIntent_.payment_method)
        // :
        //     upgradeOrDowngradeUserSubscriptionPlan(user.token, user.user._id, subItemPriceId, selectedSubscription._id, userSubscription._id)
        

        const actionFn = 
            createUserSubscriptionPlan(user.token, user.user._id, subItemPriceId, selectedSubscription._id, paymentIntent_.id, paymentIntent_.payment_method)

        actionFn
            .then((data)=>{

                console.log(data)
                
                showSnackBar("Your plan has been upgraded successfully", "success")
                setIsLoading(false)
                setIsUpdateLoading(false)
                // if(courseReducer.data.data.length > 0) {
                //     navigate(`/course/${courseReducer.data.data[0].title.toLowerCase().replaceAll(" ", "-")}`)
                // }else {
                //     navigate("/courses/")
                // }

            })
            .catch((err)=>{
                
                console.log(err)
                setIsUpdateLoading(false)
                setIsLoading(false)
                const msg = err.response && err.response.data && err.response.data.error?
                        err.response.data.error
                    :"Could not upgrade your plan, please try again later"
                showSnackBar(msg, "error")
            })


    }

    
    function showSnackBar(msg, variant = 'success'){
        enqueueSnackbar(msg, {
            variant: variant,            
            action: (key) => (
                <IconButton style={{color: '#000'}} size="small" onClick={() => closeSnackbar(key)}>
                    <Close />
                </IconButton>
            ),
    })}
    


    useEffect(() => {

        /*
            When Stripe redirects the customer to the return_url, the payment_intent_client_secret 
            query parameter is appended by Stripe.js.
            Use this to retrieve the PaymentIntent to determine what to show to your customer.

        */

        if (!stripe) {
            return
        }


        if (!urlRedirectClientSecret) {
            return
        }

        setIsUpdateLoading(true)
        stripe.retrievePaymentIntent(urlRedirectClientSecret).then(({ paymentIntent }) => {
        
            console.log("paymentIntent", paymentIntent)
            
            setPaymentIntent_(paymentIntent)
            if(paymentIntent) {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setIsSuccess(true)
                        break
                    case "processing":
                        showSnackBar("Your payment is processing.", "error")
                        setIsUpdateLoading(false)
                        break
                    case "requires_payment_method":
                        showSnackBar("Your payment was not successful, please try again.", "error")
                        setIsUpdateLoading(false)
                        break
                    default:
                        showSnackBar("Something went wrong, try again later", "error")
                        setIsUpdateLoading(false)
                        break
                }

            }
        })
        .catch((err)=>{
            
            
            setIsUpdateLoading(false)
        })
    }, [stripe, urlRedirectClientSecret])

    useEffect(()=>{
        if(isSuccess === true) {  
            
            handlePaymentSuccess()
        }         
    }, [isSuccess])

    
    
    useEffect(() => {
        if(
            subscriptionReducer.userSubscription && 
            subscriptionReducer.userSubscription.data &&
            subscriptionReducer.userSubscription.data.length > 0
        ) {
            setUserSubscription(subscriptionReducer.userSubscription.data[0])
        }
        if(subscriptionReducer.data.data.length > 0) {
            const selSub = subscriptionReducer.data.data.filter(item=>item.title.toLowerCase() === planTitle.toLowerCase())
            if(selSub.length > 0) setSelectedSubscription(selSub[0])
        }
        
    
        return () => null
    }, [subscriptionReducer])


  return (
    <Box sx={{ mt: 2, display: {md: 'flex', xs: 'flex'}}} className="justify-content-center align-items-center">
                            
        <Card className="pt-2" sx={{width: {md: '50%', xs: '95%'}, margin: {md: '16px 0px', xs: '3px 2px 3px 2px'}, padding: '6px', borderRadius: '5px'}}>
            <CardHeader
                title={
                                  
                    <Typography variant="h6" sx={{color: '#000'}}>
                        Please provide payment details below
                    </Typography>
                }
                subheader={
                    <>
                        <span className='d-block'>
                            All payment services are managed through Stripe.com, and are per stripe terms and conditions.
                        </span>
                    </>
                }
                sx={{
                    mb: 0
                }}
            />
            {
                isUpdateLoading?
                    <div className='text-center mt-3' style={{height: '400px'}}>
                        
                        <RiseLoader
                            loading={isUpdateLoading}
                            size={10}
                            color="#000"
                        />
                    </div>

                :
                <CardContent sx={{mt: 1, pt: 0}}>
                
                {/* {
                    parseFloat((totalStaff || 0) *  (planAddonPrice || 0)).toFixed(2).toString()
                } */}
                                
                    <form 
                        id="payment-form" 
                        className='w-100'
                        onSubmit={handleSubmit}
                    >
                        
                        <div>
                                                        
                            <FormControl variant="standard" fullWidth className="py-1 mb-1" >
                                <div className='d-flex mb-2'>
                                    <label htmlFor="input-with-icon-adornment4" className='py-0 my-0'>
                                        Subscription plan
                                    </label>
                                </div>
                                <input 
                                    readOnly={true}
                                    style={inputStyles}
                                    className="text-capitalize"
                                    id="input-with-icon-adornment4"
                                    value={
                                        capitalizeFirstLetter( selectedSubscription.title || "Free" ) + " ( " + itemPriceId + " )"
                                    }
                                />
                            </FormControl>
                        </div>
                        
                        <div>
                                                        
                            <FormControl variant="standard" fullWidth className="py-1 mb-1" >
                                <div className='d-flex mb-2'>
                                    <label htmlFor="input-with-icon-adornment4" className='py-0 my-0'>
                                        Price
                                    </label>
                                </div>
                                <input 
                                    readOnly={true}
                                    style={inputStyles}
                                    id="input-with-icon-adornment4"
                                    value={
                                        (selectedSubscription.currency || "USD") + " " + getPlanPrice() 
                                    }
                                />
                            </FormControl>
                        </div>


                        <div>
                            <div className='d-flex mb-2'>
                                <label htmlFor="input-with-icon-adornment4" className='py-0 mt-1 mb-0'>
                                    Payment method
                                </label>
                            </div>
                            <div style={{...inputStyles}}>
                                
                                <PaymentElement
                                    id="payment-element"
                                    className='w-100'
                                    options = {{
                                        layout: "tabs"
                                    }}
                                />
                                {/* <CardElement  
                                    className='w-100'
                                    id="payment-element" 
                                    options={CARD_ELEMENT_OPTIONS}
                                    // options = {{
                                    //     layout: "tabs"
                                    // }}
                                /> */}
                            </div>
                        </div>
                            
                        
                        <Box sx={{mt: 2}} className="d-flex">
                            <div style={{flex: 1}}></div>
                        
                            <button 
                                disabled={isLoading || !stripe || !elements} 
                                // onClick={handleSubmit}
                                id="submit"
                                type="submit"
                                className={"rounded border-0 mx-2 text-capitalize p-2 mt-3"}
                                style={{background: '#000',border: 'none', color: "#fff", boxShadow: 'none'}}
                            >
                                {
                                    isLoading?
                                        <SyncLoader
                                            loading={isLoading}
                                            size={10}
                                            color="#fff"
                                        />
                                    :"Confirm"
                                }
                            </button> 
                        </Box>
                    </form>
                </CardContent>
            }
            
            
        </Card>
    </Box>
  )
}