import { Box } from '@mui/system'
import React from 'react'
import MainTheme from '../MainTheme'

export default function HomeTheme(props) {


    return (
        <Box>
            <MainTheme showNavBar={false} />
        </Box>
    )

}