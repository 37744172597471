import React from 'react'
import { Navigate } from 'react-router'

import HomeTheme from "../Themes/HomeTheme"
import MainTheme from '../Themes/MainTheme'
import LoginPage from '../Views/Auth/login'
import CourseContentPage from '../Views/CourseContent'
import DashBoardPage from '../Views/DashBoardPage'
import PageNotFound from '../Views/PageNotFound'
import ProfilePage from '../Views/Profile'
import SettingsPage from '../Views/Settings'
import SubscriptionPlansPage from '../Views/SubscriptionPlans'
import SubscriptionSetupPage from '../Views/SubscriptionSetupPage'
import TopicContentPage from '../Views/TopicContent'


export const routes = ()=> [
    // A route object has the same properties as a <Route>
    // element. The `children` is just an array of child routes.
    {
        path: '/',
        element: <HomeTheme />,
        children: [
            { path: '/', element: <DashBoardPage /> },
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: '/pages',
        element: <MainTheme />,
        children: [
            { path: 'subscriptions', element: <SubscriptionPlansPage /> },
            { path: 'profile', element: <ProfilePage /> },
            { path: 'settings', element: <SettingsPage /> },
            { path: 'checkout/:planTitle/:itemPriceId', element: <SubscriptionSetupPage /> },
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: '/course',
        element: <MainTheme />,
        children: [
            { path: ':courseTitle', element: <CourseContentPage /> },
            { path: ':courseTitle/:chapterTitle/:topicTitle/:topicContentEncodedId', element: <CourseContentPage /> },
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: '/auth',
        element: <HomeTheme />,
        children: [
            { path: 'login', element: <LoginPage /> },
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: '404',
        element : <PageNotFound />,
        children: [
            { path: '*', element: <Navigate to="/404" replace /> },
        ]
    },
    { path: '*', element: <Navigate to="404" replace /> },
]