import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import AdbIcon from '@mui/icons-material/Adb'
import { AccountCircle, ArrowRightAlt, Logout, Settings } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { APP_NAME } from '../../Utils/constants'
import { Link, ListItemIcon } from '@mui/material'


function NavBar(props) {
    
    const { navColor, textColor, } = props
    let user = localStorage.getItem("sessionUser")
    user = user?JSON.parse(user):null
    const courseReducer = useSelector(state => state.courseReducer)
    const navigate = useNavigate()
    const [coursesMenuAnchor, setCoursesMenuAnchor] = React.useState(null)
    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [accountElNav, setAccountElNav] = React.useState(null)
    const [pages, setPages] = React.useState([
        {
            url: "/pages/subscriptions",
            title: 'Pricing',
            id: 'purchase',
        },
    ])

    const handleOpenCoursesMenu = (e) => {
        e.preventDefault()
        setCoursesMenuAnchor(e.currentTarget)
    }

    const handleCloseCoursesMenu = () => {
        setCoursesMenuAnchor(null)
    }

    
    const handleOpenAccountMenu = (event) => {
        setAccountElNav(event.currentTarget)
    }

    const handleCloseAccountMenu = () => {
        setAccountElNav(null)
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleOpenUrl = (url) => {
        navigate(url)
        handleCloseNavMenu()
    }

    const handleNavLink = (e, page) => {
        e.preventDefault()
        if(page.url){
            handleOpenUrl(page.url)
        }
    }

    const handleOpenProfile = ()=>{
        navigate('/pages/profile')
        handleCloseAccountMenu()
    }
    const handleOpenSettings = ()=>{
        navigate('/pages/settings')
        handleCloseAccountMenu()
    }

    const logout = () => {
        console.log("yeey")
        window.google.accounts.id.disableAutoSelect()
        localStorage.removeItem('sessionUser')
        // navigate('/auth/login')
        handleCloseAccountMenu()
        window.location.href = '/'
    }

    const handleCourseMenuClick = (e, title)=>{
        handleCloseCoursesMenu()
        handleNavLink(e, {url: `/course/${title.toLowerCase().replaceAll(" ", "-")}`})
    }

    function accountMenu() {
        return (
            
            <Menu
                id="basic-menu"
                anchorEl={accountElNav}
                open={Boolean(accountElNav)}
                onClose={handleCloseAccountMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleOpenProfile}>
                    <ListItemIcon>
                        <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="span" sx={{pr: 5}}>
                        Profile
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleOpenSettings}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={logout}>                    
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        )
    }
    function coursesMenu() {
        return (
            
            <Menu
                id="basic-menu"
                anchorEl={coursesMenuAnchor}
                open={Boolean(coursesMenuAnchor)}
                onClose={handleCloseCoursesMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                {
                    courseReducer.data.data.length > 0?
                    courseReducer.data.data.map((item)=>(
                        <MenuItem                             
                            onClick={(e)=>handleCourseMenuClick(e, item.title)}
                            sx={{
                                py: .5
                            }}
                        >
                            <Typography variant="span" sx={{pr: 5, fontSize: '14px' }} className="text-center">
                                {
                                    item.title
                                }
                            </Typography>
                        </MenuItem>

                    ))
                    
                :
                <Typography
                    sx={{
                        py: 2
                    }}
                >
                    0 courses found.
                </Typography>
            }
            </Menu>
        )
    }

    React.useEffect(() => {
      
        if(courseReducer.data.data.length > 0 && pages.filter(item=>item.id.toLowerCase() === "courses").length <= 0) {
            setPages([
                    {
                        url: `/course/${courseReducer.data.data[0].title.toLowerCase().replaceAll(" ", "-")}`,
                        title: "Courses",
                        id: 'courses'
                    },
                    ...pages
                ]
            )
        }

        // if(user && pages.filter(item=>item.id.toLowerCase() === "logout").length <= 0) {
        //     setPages(
        //         [
        //             ...pages,                    
        //             {
        //                 url: "",
        //                 title: 'Logout',
        //                 id: 'logout',
        //                 action: () => {
        //                     console.log("yeey")
        //                     window.google.accounts.id.disableAutoSelect()
        //                     localStorage.removeItem('sessionUser')
        //                     // navigate('/auth/login')
        //                     window.location.href = '/'
        //                 }
        //             }
        //         ]
        //     )
        // }
        // if(!user && pages.filter(item=>item.id.toLowerCase() === "login").length <= 0) {
        //     setPages(
        //         [
        //             ...pages,                    
                     
        //             {
        //                 url: "/auth/login",
        //                 title: 'Login',
        //                 id: 'login',
        //             }
        //         ]
        //     )
        // }
      return () => null
    }, [courseReducer, pages, user, navigate])
    

    return (
        <AppBar 
            position="fixed" 
            sx={{ 
                zIndex: (theme) => theme.zIndex.drawer + 1, 
                background: navColor, 
                color: textColor, 
                boxShadow: 'none', 
                borderBottom: '1px solid #e0e0e0',
            }}
        >
            <Box 
                sx={{
                    mx: 1
                }}
            >
                <Toolbar disableGutters sx={{mx: 0, px: 0}} >
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: '#4d4d4d',
                            textDecoration: 'none',
                            fontSize: '25px'
                        }}
                    >
                        {
                            APP_NAME
                        }
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem  
                                    key={page.title} 
                                    onClick={page.url?()=>handleOpenUrl(page.url):page.action}
                                >
                                    <Typography 
                                        textAlign="center"
                                        sx={{ fontSize: '26px' }}
                                    >
                                        {
                                            page.title
                                        }
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {
                            APP_NAME
                        }
                    </Typography>
                    


                    <Box 
                        sx={{ 
                            flexGrow: 0, 
                            display: { xs: 'none', md: 'flex' },
                            ml: 3,
                        }}
                    >
                        {pages.map((page) => (
                            <Link
                                href={page.url}
                                key={page.title}
                                onClick={(e)=>((page.id === "courses")?handleOpenCoursesMenu(e):handleNavLink(e, page))}
                                sx={{
                                    textDecoration: 'none',
                                    textTransform: 'capitalize',
                                    color: '#5f6368',
                                    mr: 2,
                                    "&:hover": {
                                        color: '#185abc',
                                        // backgroundColor: '#e8f0fe',
                                        // borderColor: '#e8f0fe',
                                        // borderRadius: '5px'
                                    }
                                }}
                                // color="darkGrey"
                            >
                                
                                {page.title}
                            </Link>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}></Box>

                    {
                        user?
                            <Box className="d-flex align-items-center">
                                <Box>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            mr: 2,
                                            textTransform: 'none',
                                            borderColor: '#dadce0',
                                            '&:hover': {
                                                borderColor: '#dadce0',
                                            }
                                        }}
                                    >
                                        Contact Us
                                    </Button>
                                </Box>
                                <Tooltip title="Open settings">
                                    <IconButton sx={{ p: 0 }} onClick={handleOpenAccountMenu} >
                                        <Avatar 
                                            // sx={{ width: 30, height: 30 }}
                                            alt={(user && user.user)?((user.user.fName || "Anonymous") + " " + (user.user.lName || "User")):"Anonymous User"} 
                                            src={(user && user.user)?user.user.profileImg:null}
                                        >
                                            {
                                                ((user && user.user && user.user.fName)?(user.user.fName):"Anonymous").substring(0,1)
                                            }
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        :
                            <Box className="d-flex align-items-center">
                                <Link
                                    href={'/auth/login'}
                                    onClick={(e)=>handleNavLink(e, {url: "/auth/login"})}
                                    sx={{
                                        textDecoration: 'none',
                                        textTransform: 'capitalize',
                                        color: '#5f6368',
                                        mr: 2,
                                        "&:hover": {
                                            color: '#185abc',
                                            // backgroundColor: '#e8f0fe',
                                            // borderColor: '#e8f0fe',
                                            // borderRadius: '5px'
                                        }
                                    }}
                                >

                                    Log in
                                    
                                </Link>
                                <Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(e)=>handleNavLink(e, {url: "/auth/login"})}
                                        sx={{
                                            mr: 2,
                                            textTransform: 'none',
                                            boxShadow: 'none'
                                        }}
                                        endIcon={
                                            <ArrowRightAlt />
                                        }
                                    >
                                        Join for free
                                    </Button>
                                </Box>
                            </Box>
                    }

                    {
                        accountMenu()
                    }
                    {
                        
                        coursesMenu()
                    }
                </Toolbar>
            </Box>
        </AppBar>
    )
}
export default NavBar
