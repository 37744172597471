
import { Card, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef } from 'react'
import AdbIcon from '@mui/icons-material/Adb'
import { APP_NAME, GOOGLE_CLIENT_ID } from '../../Utils/constants'
import { useNavigate } from 'react-router'
import { signUpUser } from '../../Controller/Auth'
import { Helmet } from 'react-helmet'

export default function LoginPage(props) {


    const navigate = useNavigate()
    const googleBtnRef = useRef(null)
    const redirectUrl = new URLSearchParams(window.location.search).get(
        "redirect"
    )

    useEffect(() => {
        function handleCredentialResponse(response) {
            if(response.credential) {
                // console.log(response)
                // console.log("Encoded JWT ID token: " + response.credential)
                const localUser = {
                    token: response.credential
                }
                signUpUser(response.credential)
                    .then((resp)=>{
                        // console.log("resp", resp )
                        localUser["user"] = typeof resp.data.data === 'object'?resp.data.data:{message: resp.data.data}
                        localStorage.setItem("sessionUser", JSON.stringify(localUser))
                        if(redirectUrl) {
                            navigate(redirectUrl)
                        }else {
                            navigate("/")
                        }
                        
                        

                    })
                    .catch((err)=>{
                        console.log("login error", err)
                    })
            }else {
                alert("Sorry could not login.")
            }
        }
        window.google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse
        })
        window.google.accounts.id.renderButton(
            googleBtnRef.current,
            { theme: "outline", size: "large" }  // customization attributes
        )
        window.google.accounts.id.prompt()
        return ()=> {
            window.google.accounts.id.cancel() //remove prompt once login page is destroyed
            return null
        }
    }, [])


    return (
        <Box>
            <Box sx={{my: 3}}>
                
                {/* title and meta tags for seo purposes */}
                <Helmet>
                    <title> {APP_NAME} | Login Page </title>
                    <meta name="description" content="Login page" />
                </Helmet>

                {/* content */}

                <Box className="container d-flex align-items-center justify-content-center" sx={{mt: 2, height: '90vh', }}>
                    <Card sx={{p: 0, width: {md: '400px', xs: '100%'}, mb: 2, mr: {md: 2, xs: 0}, borderRadius: '8px', borderTopRightRadius: '100px' }} >
                        <Box>

                            <Box className="justify-content-center d-flex align-items-center" sx={{minHeight: '280px', backgroundColor: '#F3D849'}}>
                                <Box>
                                    <Typography className='fw-bold text-center ' >
                                        <AdbIcon color="white" sx={{ fontSize: '50px', mr: 1, mb: 4 }} />
                                    </Typography>
                                    <Typography variant='h5' className='fw-bold text-center ' >
                                        Welcome Back
                                    </Typography>
                                    <Typography variant='body2' className='text-center' sx={{ py: 1, fontSize: '14px' }} >
                                        Sign In To Continue
                                    </Typography>
                                </Box>

                            </Box>

                            <Box>                             
                                <Box className="justify-content-center d-flex align-items-center" sx={{height: '120px'}}>
                                    
                                    {/* <Button variant="contained">
                                        Sign in with google
                                    </Button> */}
                                    <div 
                                        ref={googleBtnRef} 
                                        id="buttonDiv"
                                        data-client_id={GOOGLE_CLIENT_ID}
                                        data-auto_select="true"
                                        data-login_uri={window.location.href}
                                    ></div> 
                                </Box>
                            </Box>
                            
                        </Box>
                    </Card>
                </Box>
            </Box>

        </Box>
    )

}