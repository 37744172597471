
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SubscriptionPlanCard from '../../Components/SubscriptionPlanCard'
import { APP_NAME } from '../../Utils/constants'
import course_bg from '../../Utils/Images/subscribe.jpg'
import { getSubItemPriceId } from '../../Utils/functions'

export default function SubscriptionPlansPage(props) {


    let user = localStorage.getItem("sessionUser")
    user = user?JSON.parse(user):null
    const navigate = useNavigate()
    const subscriptionReducer = useSelector(state => state.subscriptionReducer)
    const [userSubscription, setUserSubscription] = useState({})

    

    useEffect(() => {
        if(
            subscriptionReducer && 
            subscriptionReducer.userSubscription && 
            subscriptionReducer.userSubscription.data && 
            subscriptionReducer.userSubscription.data.length > 0
        ) {
            setUserSubscription(subscriptionReducer.userSubscription.data[0])
        }
    
      return () => null
    }, [subscriptionReducer])
    
    

    return (
        <Box>
            {/* title and meta tags for seo purposes */}
            <Helmet>
                <title > Subscribe Today | Access Premium Content | Choose Plan Today | Full Time Access With No Limitations </title>
                <meta name="description" content="Subscribe Today | Access Premium Content | Choose Plan Today | Full Time Access With No Limitations." />
            </Helmet>

            {/* content */}  
            <Box 
                sx={{
                    height: {md: '300px', xs: '330px'},
                    backgroundImage: `url(${course_bg})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0,0,0,.6)',
                        top: 0,
                        left: 0,
                        width: '100%',
                        bottom: 0,
                        zIndex: 1
                    }}
                ></Box>
                <Grid container sx={{zIndex: 5, position: 'relative'}}>
                    <Grid item xs={12} md={12}>
                        <Box className="d-flex justify-content-start align-items-center" sx={{pl: {md: 5, xs: 0}, height: '280px'}} >
                            <Box sx={{px: {md: 0, xs: 2}}}>
                                <Typography color="primary" className="fw-bold" variant='h4' sx={{fontSize: {md: '40px', xs: '25px'} ,textTransform: 'capitalize', }}>
                                    Unlock Your Potential and Achieve Your Dreams.
                                </Typography>
                                <Typography className="fw-bold" variant='body2' sx={{mt: 2, textTransform: 'capitalize', color: '#fff'}}>
                                    Try { APP_NAME } membership and start a journey to turn your dreams to reality.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            <Box sx={{my: 3}}>
                {
                    subscriptionReducer.data.data.length > 0?
                        <Box className="container d-md-flex d-block justify-content-center" sx={{mt: 2}}>

                            <SubscriptionPlanCard  
                                title={"Free"}
                                description={"Explore various course tutorials and experience the best of our platform, absolutely FREE. Upgrade to premium anytime and enjoy even more features and benefits."}
                                plan={subscriptionReducer.data.data[0]} 
                                itemPriceType={""}
                                userSubscription={userSubscription}
                                isCurrentPlan={
                                    userSubscription.planId === subscriptionReducer.data.data[0]._id // if subscription is free
                                    ||
                                    !( /// subscription is expired then show free as current plan
                                        userSubscription.planId === subscriptionReducer.data.data[1]._id && 
                                        userSubscription.status.toLowerCase() === "active" &&
                                        userSubscription.endsAt &&
                                        new Date(parseFloat(userSubscription.endsAt)) > new Date()
                                    )
                                }
                            />
                            <SubscriptionPlanCard 
                                title={"Monthly Plan"}
                                description={"Enjoy unlimited access to the courses for one month. Subscribe today and start enjoying the convenience and flexibility of a monthly plan!"}
                                plan={subscriptionReducer.data.data[1]} 
                                itemPriceType={"monthly"}
                                userSubscription={userSubscription}
                                isCurrentPlan={
                                    userSubscription.planId === subscriptionReducer.data.data[1]._id && 
                                    userSubscription.status.toLowerCase() === "active" &&
                                    userSubscription.itemPriceId === getSubItemPriceId(subscriptionReducer.data.data[1], true) &&
                                    userSubscription.endsAt &&
                                    new Date(parseFloat(userSubscription.endsAt)) > new Date()
                                }
                            />
                            <SubscriptionPlanCard 
                                title={"Annual Plan"}
                                description={"Get a Full Year of Savings with Our Annual Subscription Plan! Get to enjoy access to all course tutorials, materials and updated for a full year."}
                                plan={subscriptionReducer.data.data[1]} 
                                itemPriceType={"yearly"}
                                userSubscription={userSubscription}
                                isCurrentPlan={
                                    userSubscription.planId === subscriptionReducer.data.data[1]._id &&                                     
                                    userSubscription.status.toLowerCase() === "active" &&
                                    userSubscription.itemPriceId === getSubItemPriceId(subscriptionReducer.data.data[1], false) &&
                                    userSubscription.endsAt &&
                                    new Date(parseFloat(userSubscription.endsAt)) > new Date()
                                }
                            />
                        </Box>
                    :
                        ""
                }
            </Box>

        </Box>
    )

}